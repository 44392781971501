import { useEffect, useState, useRef } from "react";
import AnimateHeight from "react-animate-height";

export default (props) => {
  const { children } = props;

  const [height, setHeight] = useState("auto");

  const animateRef = useRef();

  const setFixedHeight = () => {
    setHeight(animateRef.current.clientHeight);
  };

  useEffect(() => {
    setFixedHeight();
  }, []);

  // useEffect running on all updates except first render
  const isFirst = useRef(true);

  useEffect(() => {
    if (!isFirst.current) {
      setHeight("auto");
    }
  }, [children]);

  useEffect(() => {
    isFirst.current = false;
  }, []);

  return (
    <AnimateHeight
      height={height}
      onHeightAnimationEnd={setFixedHeight}
      ref={animateRef}
      duration={200}
      style={{ width: "100%" }}
    >
      {children}
    </AnimateHeight>
  );
};
