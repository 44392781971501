import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { addToStateArray, removeFromStateArray } from "modules/functions";

import Posts from "./Posts";

export default () => {
  const navigate = useNavigate();

  const [switchLoading, setSwitchLoading] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [tutorialBox, setTutorialBox] = useState(false);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      hasViews: user.hasViews,
    })
  );

  const { hasViews } = useSelector(UserProperties);

  // Fetch data
  const GET_POSTS_DATA = gql`
    query GetPostsData {
      posts {
        id
        created_at
        name
        status
        on_hold
        switched_on
        text
        image
        image_link
        posts_stats {
          id
          to
          total_impressions
          total_interactions
          total_spent
        }
        impressions {
          id
          registered_at
          interacted
          cpi
          country
        }
      }
    }
  `;

  const { loading, data, error, refetch } = useQuery(GET_POSTS_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching posts error", error);
    //captureException(error);
  }

  // handle Post switch
  const TOGGLE_POST_SWITCH = gql`
    mutation TogglePostSwitch($postId: ID!) {
      togglePostSwitch(postId: $postId) {
        id
        status
        switched_on
      }
    }
  `;

  const [togglePostSwitch] = useMutation(TOGGLE_POST_SWITCH);

  const onSwitchClick = async (postId) => {
    console.log("onSwitchClick", postId);
    try {
      if (!postId) return;

      if (switchLoading.includes(postId)) return;
      console.log("wiil toggle", postId);

      addToStateArray(switchLoading, postId, setSwitchLoading);

      const result = await togglePostSwitch({
        variables: {
          postId,
        },
      });

      removeFromStateArray(switchLoading, postId, setSwitchLoading);

      console.log("result", result);
    } catch (error) {
      return;
    }
  };

  // handle Post edit
  const onEditClick = (postId) => {
    navigate("/modify-post?id=" + postId);
  };

  // handle Post delete
  const onDeleteClick = (postId) => {
    setConfirmDelete(postId);
  };

  const closeDeleteModal = () => {
    setConfirmDelete(null);
  };

  const DELETE_POST = gql`
    mutation DeletePost($postId: ID!) {
      deletePost(postId: $postId) {
        resultCode
      }
    }
  `;

  const [deletePost] = useMutation(DELETE_POST);

  const onConfirmDelete = async () => {
    try {
      if (!confirmDelete) return;

      if (loadingDelete) return;

      setLoadingDelete(true);

      const result = await deletePost({
        variables: {
          postId: confirmDelete,
        },
      });

      console.log("result", result);
      refetch();

      setLoadingDelete(false);
      setConfirmDelete(null);
    } catch (error) {
      setLoadingDelete(false);
      return;
    }
  };

  // handle Post duplicate
  const onDuplicateClick = (postId) => {
    navigate("/modify-post?id=" + postId + "&duplicate=true");
  };

  // handle Tutorial box
  const closeBox = () => {
    setTutorialBox(false);

    localStorage.setItem("closed-discover-t-box", "y");
  };

  useEffect(() => {
    const closedBox = localStorage.getItem("closed-discover-t-box");

    if (!hasViews && !closedBox) setTutorialBox(true);
    else setTutorialBox(false);
  }, [hasViews]);

  console.log({ data });

  return (
    <Posts
      loading={loading}
      data={data}
      onSwitchClick={onSwitchClick}
      switchLoading={switchLoading}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      confirmDelete={confirmDelete}
      closeDeleteModal={closeDeleteModal}
      onConfirmDelete={onConfirmDelete}
      loadingDelete={loadingDelete}
      onDuplicateClick={onDuplicateClick}
      tutorialBox={tutorialBox}
      closeBox={closeBox}
    />
  );
};
