import React from "react";
import styled from "styled-components";

import * as css from "styles/CssVariables.js";

import appleIcon from "assets/img/icons/icons8-apple.svg";
import googleIcon from "assets/img/icons/icons8-google.svg";
import facebookIcon from "assets/img/icons/facebook.png";

const Button = styled.div`
  box-sizing: border-box;
  height: 42px;
  padding: ${(props) => (props.big ? "0 15px" : "0 40px")};
  width: ${(props) => (props.big ? "100%" : "auto")};

  background: #fff;
  border-radius: 21px;
  border: 0;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${(props) =>
    !props.disabled &&
    `&:hover {
    cursor: pointer;

    color: #fff;

    background: ${css.BRAND_BLUSH};
  }`}
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const Text = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${css.DARK_BLUE};
`;

export default (props) => {
  const { onClick, parameters, id, social, big, disabled } = props;

  let icon;
  let text;

  switch (social) {
    case "apple":
      icon = appleIcon;
      text = "Continue with Apple";
      break;
    case "google":
      icon = googleIcon;
      text = "Continue with Google";
      break;
    case "facebook":
      icon = facebookIcon;
      text = "Continue with Facebook";
      break;
  }

  return (
    <Button
      onClick={() => {
        if (disabled) return;
        onClick(parameters);
      }}
      id={id}
      disabled={disabled}
      big={big}
    >
      <Icon>
        <img src={icon} alt={text} style={{ width: "100%" }} />
      </Icon>
      {big && <Text>{text}</Text>}
    </Button>
  );
};
