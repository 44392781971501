import { useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import Layout from "components/Layout";
import PageTitle from "components/text/PageTitle";
import WalletBalancesContainer from "./WalletBalancesContainer";
import TransactionsContainer from "./TransactionsContainer";
import SectionTitle from "components/text/SectionTitle";

const Wallet = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Wallet | Scalelup";
  }, []);

  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  return (
    <Layout>
      <Wallet>
        <PageTitle>Wallet</PageTitle>
        <WalletBalancesContainer />
        <SectionTitle>Coins Transactions</SectionTitle>
        <TransactionsContainer />
      </Wallet>
    </Layout>
  );
};
