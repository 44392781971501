import { useCallback, useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { POST_VIEW_TIME } from "constants";
import Discover from "./Discover";
import { useNavigate } from "react-router-dom";

// Actions
import { updateProgressViews } from "store/actions";

export default () => {
  const [key, setKey] = useState(1);

  const remountRequest = () => {
    setKey((prevKey) => {
      return prevKey + 1;
    });
  };

  return <DiscoverContainer key={key} remountRequest={remountRequest} />;
};

const DiscoverContainer = ({ remountRequest }) => {
  const container = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [impression, setImpression] = useState({
    shardNumber: null,
    impressionId: null,
    post: null,
    probability: null,
    reward: null,
  });
  const [waitingImpression, setWaitingImpression] = useState({
    shardNumber: null,
    impressionId: null,
    post: null,
    probability: null,
    reward: null,
    postLiked: false,
    likes: 0,
  });
  const [status, setStatus] = useState(null);
  const [counter, setCounter] = useState(0);
  const [nextButton, setNextButton] = useState(false);
  const [probabilityBox, setProbabilityBox] = useState(false);
  const [noPost, setNoPost] = useState(false);
  const [requestChatLoading, setRequestChatLoading] = useState(false);
  const [postLiked, setPostLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const [interacted, setInteracted] = useState(false);
  const [firstView, setFirstView] = useState(true);
  const [reportMenuOpened, setReportMenuOpened] = useState(false);
  const [postReported, setPostReported] = useState(false);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      hasViews: user.hasViews,
    })
  );

  const { hasViews } = useSelector(UserProperties);

  // Prepare Mutations
  const REQUEST_IMPRESSION = gql`
    mutation RequestImpression {
      requestImpression {
        impression {
          id
          #   requested_at
        }
        post {
          id
          created_at
          text
          image
          image_link
          user {
            id
            username
            profile_image
          }
        }
        shardNumber
        probability
        reward
        postLiked
        likes
        postReported
      }
    }
  `;

  const [requestImpression] = useMutation(REQUEST_IMPRESSION);

  const REGISTER_IMPRESSION = gql`
    mutation RegisterImpression(
      $impressionId: ID!
      $shardNumber: Int!
      $firstView: Boolean!
    ) {
      registerImpression(
        impressionId: $impressionId
        shardNumber: $shardNumber
        firstView: $firstView
      ) {
        status
      }
    }
  `;

  const [registerImpression] = useMutation(REGISTER_IMPRESSION);

  const REGISTER_REWARD = gql`
    mutation RequestReward {
      registerReward {
        status
      }
    }
  `;

  const [registerReward] = useMutation(REGISTER_REWARD);

  const REGISTER_INTERACTION = gql`
    mutation RegisterInteraction($impressionId: ID!, $shardNumber: Int!) {
      registerInteraction(
        impressionId: $impressionId
        shardNumber: $shardNumber
      ) {
        status
      }
    }
  `;

  const [registerInteraction] = useMutation(REGISTER_INTERACTION);

  const LIKE_POST = gql`
    mutation LikePost($postId: ID!, $shardNumber: Int!) {
      likePost(postId: $postId, shardNumber: $shardNumber) {
        status
      }
    }
  `;

  const [likePost] = useMutation(LIKE_POST);

  const REPORT_POST = gql`
    mutation ReportPost($postId: ID!, $shardNumber: Int!, $reason: String!) {
      reportPost(postId: $postId, shardNumber: $shardNumber, reason: $reason) {
        status
      }
    }
  `;

  const [reportPost] = useMutation(REPORT_POST);

  // Request impression function
  const sendRequest = async () => {
    try {
      setStatus("requestSent");

      const result = await requestImpression();
      console.log("Request impression result", result);

      return result.data.requestImpression;
    } catch (error) {
      console.log("Error Request impression", error);
    }
  };

  // Get first post on load
  useEffect(() => {
    const getFirstPost = async () => {
      const impression = await sendRequest();
      //console.log("getFirstPost impression", impression);

      if (impression?.impression) {
        setImpression({
          shardNumber: impression.shardNumber,
          impressionId: impression.impression.id,
          post: impression.post,
          probability: impression.probability,
        });

        setPostLiked(impression.postLiked);
        setLikes(impression.likes);
        setPostReported(impression.postReported);

        setStatus("counting");
        setProbabilityBox(true);
      } else if (impression?.reward) {
        setImpression({
          shardNumber: null,
          impressionId: null,
          post: null,
          probability: null,
          reward: impression.reward,
        });

        setPostLiked(false);
        setLikes(0);
        setPostReported(false);

        setStatus("waiting");
      } else {
        // No post returned
        setNoPost(true);
      }
    };

    getFirstPost();
  }, []);

  // Start counter on Impression change
  useEffect(() => {
    if (impression.impressionId) {
      setCounter(0);

      const interval = setInterval(() => {
        setCounter((prevCounter) => {
          const newCounter = prevCounter + 1;
          if (newCounter >= POST_VIEW_TIME) clearInterval(interval);
          return newCounter;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [impression]);

  const sendRewardRegistration = async () => {
    try {
      setStatus("registerSent");

      const result = await registerReward();

      console.log("Register reward result", result.data.registerReward.status);

      if (result.data.registerReward.status === "Registered") {
        const impression = await sendRequest();

        if (impression.impression) {
          setWaitingImpression({
            shardNumber: impression.shardNumber,
            impressionId: impression.impression.id,
            post: impression.post,
            probability: impression.probability,
            reward: impression.reward,
            postLiked: impression.postLiked,
            likes: impression.likes,
          });

          setStatus("waiting");
        } else {
          // No post returned
          setNoPost(true);
        }
      }
    } catch (error) {
      console.log("Error Register reward", error);
    }
  };

  // Regsiter Reward and request new Impression function
  useEffect(() => {
    if (impression.reward) {
      sendRewardRegistration();
    }
  }, [impression]);

  // Regsiter impression and request new one function
  const sendRegistration = async () => {
    try {
      setStatus("registerSent");

      const result = await registerImpression({
        variables: {
          impressionId: impression.impressionId,
          shardNumber: impression.shardNumber,
          firstView,
        },
      });

      console.log(
        "Register impression result",
        result.data.registerImpression.status
      );

      if (result.data.registerImpression.status === "Registered") {
        setFirstView(false);

        const impression = await sendRequest();
        if (impression.impression) {
          setWaitingImpression({
            shardNumber: impression.shardNumber,
            impressionId: impression.impression.id,
            post: impression.post,
            probability: impression.probability,
            reward: impression.reward,
            postLiked: impression.postLiked,
            likes: impression.likes,
            postReported: impression.postReported,
          });

          setStatus("waiting");
        } else if (impression.reward) {
          setWaitingImpression({
            shardNumber: null,
            impressionId: null,
            post: null,
            probability: null,
            postLiked: false,
            likes: 0,
            reward: impression.reward,
            postReported: false,
          });

          setStatus("waiting");
        } else {
          // No post returned
          setNoPost(true);
        }

        if (!hasViews) {
          dispatch(updateProgressViews(true));
        }
      }
    } catch (error) {
      console.log("Error Register impression", error);
    }
  };

  // Register impression when it's time
  useEffect(() => {
    const advance = 4; // advance time to send impression registration

    // check if tab is visible first
    if (counter === POST_VIEW_TIME - advance - 1) {
      if (!isTabVisible && !interacted) {
        setCounter(0);
      }
    }

    if (counter === POST_VIEW_TIME - advance) {
      sendRegistration();
    }

    if (counter === POST_VIEW_TIME - 1) {
      setProbabilityBox(false);
    }
  }, [counter]);

  // Show Next button when post time finished and waiting post ready
  useEffect(() => {
    if (
      counter >= POST_VIEW_TIME &&
      (waitingImpression.impressionId || waitingImpression.reward || noPost)
    ) {
      setNextButton(true);
    }

    // Show button when Reward found
    if (impression.reward && (waitingImpression.impressionId || noPost)) {
      setNextButton(true);
    }
  }, [counter, waitingImpression, noPost]);

  // handle Post change
  const switchPost = () => {
    setImpression(waitingImpression);

    setPostLiked(waitingImpression.postLiked);
    setLikes(waitingImpression.likes);
    setInteracted(false);
    setPostReported(waitingImpression.postReported);

    setWaitingImpression({
      shardNumber: null,
      impressionId: null,
      post: null,
      probability: null,
      reward: null,
      postLiked: false,
      likes: 0,
      postReported: false,
    });

    setStatus("counting");
  };

  gsap.registerPlugin(useGSAP);
  const { contextSafe } = useGSAP({ scope: container });

  // Show next Post click
  const showNextPost = contextSafe(() => {
    if (noPost) remountRequest();

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 600);

    const postTl = gsap.timeline({ paused: true });
    postTl.to("#postBox", {
      y: -window.innerHeight,
      duration: 0.35,
      ease: "power1.inOut",
    });
    postTl.call(switchPost);
    postTl.to("#postBox", { y: 0, duration: 0.35, ease: "power1.inOut" });

    postTl.play();

    setNextButton(false);
    setProbabilityBox(true);
  });

  // control Next button
  const showNextButton = contextSafe(() => {
    const buttonTl = gsap.timeline({ paused: true });
    buttonTl.to("#nextButton", { display: "block", duration: 0 });
    buttonTl.to("#nextButton", {
      opacity: 1,
      duration: 0.15,
    });

    buttonTl.play();
  });

  const hideNextButton = contextSafe(() => {
    const buttonTl = gsap.timeline({ paused: true });
    buttonTl.to("#nextButton", {
      opacity: 0,
      duration: 0.15,
    });
    buttonTl.to("#nextButton", { display: "none", duration: 0 });

    buttonTl.play();
  });

  useEffect(() => {
    if (nextButton) {
      showNextButton();
    }

    if (!nextButton) {
      hideNextButton();
    }
  }, [nextButton]);

  // control Coins probability box
  const showProbabilityBox = contextSafe(() => {
    const probabilityTl = gsap.timeline({ paused: true });
    probabilityTl.to("#probabilityBox", {
      opacity: 1,
      duration: 0,
      delay: 1.5,
    });
    probabilityTl.to("#probabilityBox", { y: 0 });

    probabilityTl.play();
  });

  const hideProbabilityBox = contextSafe(() => {
    const probabilityTl = gsap.timeline({ paused: true });
    probabilityTl.to("#probabilityBox", { y: -180, delay: 0.3 });
    probabilityTl.to("#probabilityBox", { opacity: 0, duration: 0 });

    probabilityTl.play();
  });

  useEffect(() => {
    if (probabilityBox && !impression.reward && !waitingImpression.reward) {
      showProbabilityBox();
    }

    if (!probabilityBox) {
      hideProbabilityBox();
    }
  }, [probabilityBox]);

  // preload Post image
  useEffect(() => {
    if (waitingImpression.post?.image) {
      const img = new Image();
      img.src = waitingImpression.post.image;
    }
  }, [waitingImpression]);

  // handle Interactions
  const linkClicked = async (e) => {
    setInteracted(true);

    try {
      await registerInteraction({
        variables: {
          impressionId: impression.impressionId,
          shardNumber: impression.shardNumber,
        },
      });
    } catch (error) {
      console.log("linkClicked error", error);
    }
  };

  // handle New Message click
  const REQUEST_CHAT = gql`
    mutation RequestChat($postUserId: ID!) {
      requestChat(postUserId: $postUserId) {
        id
      }
    }
  `;

  const [requestChat] = useMutation(REQUEST_CHAT);

  const onNewMessageClick = async () => {
    try {
      if (requestChatLoading) return;

      setRequestChatLoading(true);

      const result = await requestChat({
        variables: {
          postUserId: impression.post.user.id,
        },
      });
      console.log("Request chat result", result);

      if (!result.data.requestChat) {
        navigate(
          `/createchat?postid=${impression.post.id}&sn=${impression.shardNumber}`
        );
      } else {
        navigate(`/chat?id=${result.data.requestChat.id}`);
      }

      setRequestChatLoading(false);
    } catch (error) {
      setRequestChatLoading(false);
      console.log("requestChat error", error);
    }
  };

  // handle Like post
  const likePostClick = async () => {
    try {
      if (postLiked) return;

      setPostLiked(true);
      setLikes((prevState) => prevState + 1);

      await likePost({
        variables: {
          postId: impression.post.id,
          shardNumber: impression.shardNumber,
        },
      });
    } catch (error) {
      console.log("likePostClick error", error);
    }
  };

  const counterWidth = (100 / POST_VIEW_TIME) * counter;

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // handle Report post
  const reportPostMenuClick = () => {
    if (postReported) return;
    setReportMenuOpened((prevState) => !prevState);
  };

  const closeReportMenu = () => {
    setReportMenuOpened(false);
  };

  const onReportPost = async (reason) => {
    try {
      if (postReported) return;

      setPostReported(true);
      setReportMenuOpened(false);

      await reportPost({
        variables: {
          postId: impression.post.id,
          shardNumber: impression.shardNumber,
          reason,
        },
      });
    } catch (error) {
      console.log("likePostClick error", error);
    }
  };

  console.log("counter", counter);
  console.log("status", status);
  console.log("IMPRESSION", impression);
  console.log("Waiting IMPRESSION", waitingImpression);
  console.log({ interacted });
  console.log({ isTabVisible });

  return (
    <Discover
      post={impression.post}
      counterWidth={counterWidth}
      showNextPost={showNextPost}
      container={container}
      probability={impression.probability}
      reward={impression.reward}
      tryAgain={!impression.impressionId && !impression.reward && noPost}
      onTryAgainClick={remountRequest}
      linkClicked={linkClicked}
      onNewMessageClick={onNewMessageClick}
      likePostClick={likePostClick}
      postLiked={postLiked}
      likes={likes}
      reportMenuOpened={reportMenuOpened}
      closeReportMenu={closeReportMenu}
      reportPostMenuClick={reportPostMenuClick}
      onReportPost={onReportPost}
      postReported={postReported}
    />
  );
};
