import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import Menu from "./Menu";

const DesktopMenu = styled.div`
  width: 220px;
  box-sizing: border-box;

  margin: 0;
  padding: 20px;

  border-radius: ${css.BOX_RADIUS_PRIMARY};
  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;
`;

export default ({ unreadMessages }) => {
  return (
    <DesktopMenu>
      <Menu unreadMessages={unreadMessages} />
    </DesktopMenu>
  );
};
