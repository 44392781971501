import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import globalConstants from "constants/globalConstants";
import { AWS_PROFILE_IMAGES_URL, BACKEND_SERVER_URL } from "constants";
import { DEFAULT_USERNAMES } from "constants/usernames";
import { sanitizedUsername } from "modules/functions";

import UpdateProfile from "./UpdateProfile";

const OnboardingContainer = styled.div`
  /* position: fixed;
  top: 50%;
  left: 50%; */
  margin: 60px auto 0;
  width: 100%;
  max-width: 600px;
  /* transform: translateX(-50%) translateY(-50%); */

  padding: 0 10px;
  box-sizing: border-box;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin: 30px auto 0;
  }
`;

export default () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [avatarPosition, setAvatarPosition] = useState(100);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    error: null,
  });

  // create array of default avatars and save it to state
  const createDefaultAvatarsArray = () => {
    const arr = Array.from({ length: 200 }, (_, index) => index + 1001);

    const imagesArr = arr.map((item) => AWS_PROFILE_IMAGES_URL + item + ".svg");

    for (let i = imagesArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [imagesArr[i], imagesArr[j]] = [imagesArr[j], imagesArr[i]];
    }

    return imagesArr;
  };

  useEffect(() => {
    const avatarArray = createDefaultAvatarsArray();

    setAvatars(avatarArray);
  }, []);

  // Fetch data
  const GET_USER_ONBOARDING_DATA = gql`
    query GetUserOnboardingData {
      userOnboardingData {
        id
        username
        profile_image
        onboarding
      }
    }
  `;

  const {
    loading: loadingData,
    data,
    error,
  } = useQuery(GET_USER_ONBOARDING_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching user data error", error);
    //captureException(error);
  }

  console.log("data", data);

  useEffect(() => {
    // set Avatar position to default profile picture
    if (data?.userOnboardingData.profile_image) {
      const defaultPosition = avatars.indexOf(
        AWS_PROFILE_IMAGES_URL + data.userOnboardingData.profile_image
      );
      console.log({ defaultPosition });

      //setAvatarPosition(defaultPosition);
      if (defaultPosition !== -1) setAvatarPosition(defaultPosition);
      else {
        // add current profile image in the list of avatars
        let newAvatars = [
          ...avatars.slice(0, avatarPosition + 1),
          AWS_PROFILE_IMAGES_URL + data.userOnboardingData.profile_image,
          ...avatars.slice(avatarPosition + 1),
        ];

        setAvatars(newAvatars);
        setAvatarPosition(avatarPosition + 1);
      }
    }

    // populate Username state if onboarding step already completed
    if (data?.userOnboardingData.onboarding === 1) {
      setUsername(data.userOnboardingData.username);
    }

    // redirect to home if onboarding already completed
    if (data?.userOnboardingData.onboarding === 2) {
      navigate("/home");
    }
  }, [data]);

  const handleInputChange = (e) => {
    const sanitized = sanitizedUsername(e.target.value);

    setUsername(sanitized);
  };

  // handle username generation
  function getRandomItemFromArray(arr) {
    if (arr.length === 0) {
      return null;
    }

    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }

  const onGenerateClick = () => {
    const generatedUsername = getRandomItemFromArray(DEFAULT_USERNAMES);

    setUsername(generatedUsername);
  };

  const UPDATE_ONBOARDING = gql`
    mutation UpdateOnboarding($step: Int!) {
      updateOnboarding(step: $step) {
        resultCode
      }
    }
  `;

  const [updateOnboarding] = useMutation(UPDATE_ONBOARDING);

  // update username
  const UPDATE_USERNAME = gql`
    mutation UpdateUsername($username: String!) {
      updateUsername(username: $username) {
        id
        username
      }
    }
  `;

  const [updateUsername] = useMutation(UPDATE_USERNAME);

  const onFirstContinueClick = async () => {
    try {
      if (loadingUpdate) return;

      console.log("onFirstContinueClick");
      setStep(2);

      if (username !== "") {
        console.log("Will update username");
        setLoadingUpdate(true);

        const result = await updateUsername({
          variables: {
            username,
          },
        });

        console.log("result", result);

        await updateOnboarding({
          variables: {
            step: 1,
          },
        });

        setLoadingUpdate(false);
      }
    } catch (error) {
      //captureException(error);
      console.log(error);
      setLoadingUpdate(false);

      return;
    }
  };

  const onBackClick = () => {
    setStep(1);
  };

  // handle Profile picture selection
  // browse through list of avatars
  const onMoveClick = (side) => {
    let newPosition = avatarPosition;

    if (side === "left") newPosition--;
    else newPosition++;

    if (newPosition > avatars.length - 1) newPosition = 0;
    if (newPosition < 0) newPosition = avatars.length - 1;

    setAvatarPosition(newPosition);
  };

  let editor = "";

  // handle Avatar editor
  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleUploadSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleUploadCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const fileNameParts = e.target.files[0].name.split(".");
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

      console.log("fileExtension", fileExtension);

      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        setPicture({
          ...picture,
          error: "Invalid file type!",
        });

        return;
      }

      let url = URL.createObjectURL(e.target.files[0]);

      console.log({ url });
      setPicture({
        ...picture,
        img: url,
        cropperOpen: true,
      });
    }
  };

  // handle profile image cloud upload
  const axiosApi = axios.create({
    baseURL: BACKEND_SERVER_URL,
  });

  async function postFile(url, data, config = {}) {
    return axiosApi
      .post(url, data, { ...config })
      .then((response) => response.data);
  }

  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/png" });
  }

  const handleUploadSave = async (e) => {
    try {
      if (setEditorRef) {
        setLoadingUpdate(true);

        const canvasScaled = editor.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();

        setPicture({
          ...picture,
          img: null,
          cropperOpen: false,
        });

        var blob = dataURItoBlob(croppedImg);

        const formData = new FormData();
        formData.append("file", blob);

        const result = await postFile("/upload/profile-picture", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        console.log("UPLOAD result", result);

        let newAvatars = [
          ...avatars.slice(0, avatarPosition + 1),
          AWS_PROFILE_IMAGES_URL + result.fileName,
          ...avatars.slice(avatarPosition + 1),
        ];

        setAvatars(newAvatars);
        setAvatarPosition(avatarPosition + 1);

        setLoadingUpdate(false);
      }
    } catch (error) {
      console.log("handleUploadSave error", error);
    }
  };

  // update profile image
  const UPDATE_PROFILE_IMAGE = gql`
    mutation UpdateProfileImage($image: String!) {
      updateProfileImage(image: $image) {
        id
        profile_image
      }
    }
  `;

  const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE);

  const onSecondContinueClick = async () => {
    try {
      if (loadingUpdate) return;

      console.log("onSecondContinueClick");
      console.log("Will update profile image");

      setLoadingUpdate(true);

      const parsedUrl = new URL(avatars[avatarPosition]);
      const filename = parsedUrl.pathname.slice(1);

      const result = await updateProfileImage({
        variables: {
          image: filename,
        },
      });

      console.log("result", result);

      await updateOnboarding({
        variables: {
          step: 2,
        },
      });

      window.location = "/modify-post";
    } catch (error) {
      //captureException(error);
      console.log(error);
      setLoadingUpdate(false);

      return;
    }
  };

  // console.log("avatars", avatars);
  console.log("avatarPosition", avatarPosition);
  console.log("avatars[avatarPosition]", avatars[avatarPosition]);
  console.log("picture", picture);

  return (
    <OnboardingContainer>
      <UpdateProfile
        isOnboarding={true}
        step={step}
        username={username}
        handleInputChange={handleInputChange}
        defaultUsername={data?.userOnboardingData.username}
        loading={loadingData || loadingUpdate}
        onFirstContinueClick={onFirstContinueClick}
        onBackClick={onBackClick}
        onGenerateClick={onGenerateClick}
        profilePicture={avatars[avatarPosition]}
        onMoveClick={onMoveClick}
        cropperOpen={picture.cropperOpen}
        picture={picture}
        setEditorRef={setEditorRef}
        handleFileChange={handleFileChange}
        handleUploadSlider={handleUploadSlider}
        handleUploadCancel={handleUploadCancel}
        handleUploadSave={handleUploadSave}
        onSecondContinueClick={onSecondContinueClick}
        onboarding={data?.userOnboardingData.onboarding}
      />
    </OnboardingContainer>
  );
};
