import React from "react";

// import public pages
import Landing from "components/Landing";
import Dashboard from "components/dashboard/Dashboard";
import PostsContainer from "components/posts/PostsContainer";
import CreatePostContainer from "components/posts/CreatePostContainer";
import VerifyAccountEmailContainer from "components/account/VerifyAccountEmailContainer";
import ResetPasswordLoginContainer from "components/account/ResetPasswordLoginContainer";
import LogoutContainer from "components/account/LogoutContainer";
import OnboardingContainer from "components/settings/OnboardingContainer";
import DiscoverContainer from "components/discover/DiscoverContainer";
import ChatContainer from "components/chat/ChatContainer";
import MessagesContainer from "components/chat/MessagesContainer";
import CreateChatContainer from "components/chat/CreateChatContainer";
import Referrals from "components/referrals/Referrals";
import Wallet from "components/wallet/Wallet";
import Settings from "components/settings/Settings";
import VerifyAddedEmailContainer from "components/account/VerifyAddedEmailContainer";
import ContestContainer from "components/contest/ContestContainer";
import UpdateProfileContainer from "components/settings/UpdateProfileContainer";
import MagicLoginContainer from "components/account/MagicLoginContainer";
import PrivacyPolicy from "components/PrivacyPolicy";
import Terms from "components/Terms";
import SuperLoginContainer from "components/account/SuperLoginContainer";
import DataDeletionStatusContainer from "components/account/DataDeletionStatusContainer";

// import protected pages
//import Landing from "../components/Landing";

const publicRoutes = [
  {
    path: "/",
    component: <Landing />,
  },
  {
    path: "/verify-account-email",
    component: <VerifyAccountEmailContainer />,
  },
  {
    path: "/reset-password-login",
    component: <ResetPasswordLoginContainer />,
  },
  {
    path: "/verify-added-email",
    component: <VerifyAddedEmailContainer />,
  },
  {
    path: "/magic-login",
    component: <MagicLoginContainer />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    component: <Terms />,
  },
  {
    path: "/s-login",
    component: <SuperLoginContainer />,
  },
  {
    path: "/account-status",
    component: <DataDeletionStatusContainer />,
  },
];

const authProtectedRoutes = [
  {
    path: "/home",
    component: <Dashboard />,
  },
  {
    path: "/discover",
    component: <DiscoverContainer />,
  },
  {
    path: "/messages",
    component: <MessagesContainer />,
  },
  {
    path: "/posts",
    component: <PostsContainer />,
  },
  {
    path: "/modify-post",
    component: <CreatePostContainer />,
  },
  {
    path: "/logout",
    component: <LogoutContainer />,
  },
  {
    path: "/onboarding",
    component: <OnboardingContainer />,
  },
  {
    path: "/chat",
    component: <ChatContainer />,
  },
  {
    path: "/createchat",
    component: <CreateChatContainer />,
  },
  {
    path: "/referrals",
    component: <Referrals />,
  },
  {
    path: "/wallet",
    component: <Wallet />,
  },
  {
    path: "/settings",
    component: <Settings />,
  },
  {
    path: "/contest",
    component: <ContestContainer />,
  },
  {
    path: "/updateprofile",
    component: <UpdateProfileContainer />,
  },
];

export { authProtectedRoutes, publicRoutes };
