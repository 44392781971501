import globalConstants from "constants/globalConstants";

let isMobileScreen = false;

const width = window.innerWidth;

if (width < globalConstants.switchMobilePx) isMobileScreen = true;
else isMobileScreen = false;

// colors
export const BRAND_YELLOW = "#FFE70D";
export const BRAND_GREEN = "#5DD39E";
export const BRAND_INDIGO = "#8783D1";
export const BRAND_BLUSH = "#EEE5E9";

export const DARK_BLUE = "#131F2A";
export const PAGE_BACKGROUND = "#131F2B";
export const LANDING_PAGE_BACKGROUND = "#0a151f";
export const DARK_BOX_BACKGROUND = "#131E28";
export const FLAT_BOX_BACKGROUND = "#2d4154";
export const FLAT_BOX_HOVER_BACKGROUND = "#31465b";
export const ROUND_ICON_BACKGROUND = "#2d4154";
// export const TEXT_GREY = "#DBDBDB";
export const TEXT_GREY = "#c5c5c5";
export const LIGHT_BLUE = "#4891FF";
export const ERROR_RED = "#FF4869";
export const POSITIVE_GREEN = "#53A552";
export const NEGATIVE_RED = "#FF4869";

export const BLUE_BOX_GRADIENT =
  "linear-gradient(138deg, #2B4055 1.65%, #1A2733 63.37%);";
export const BLUE_BOX_GRADIENT_HOVER =
  "linear-gradient(138deg, #2d445b 1.65%, #1c2b38 63.37%);";

// sites, gaps and paddings
export const MIDDLE_COLUMN_STANDARD_WIDTH = "654px";
export const BUTTONS_GAP = "15px";
export const BUTTONS_GAP_SMALL = "10px";
export const VERTICAL_BOX_MARGIN = "12px";
export const VERTICAL_BOX_MARGIN_MEDIUM = "18px";
export const VERTICAL_BOX_MARGIN_LARGE = "24px";
export const VERTICAL_BOX_MARGIN_MLARGE = "30px";
export const VERTICAL_BOX_MARGIN_XLARGE = "36px";
export const BOX_PADDING_LARGE = !isMobileScreen ? "40px" : "30px 15px";
export const BOX_PADDING_PRIMARY = !isMobileScreen ? "25px 30px" : "25px 20px";
export const BOX_PADDING_SECONDARY = !isMobileScreen
  ? "20px 25px"
  : "15px 15px";
export const BOX_PADDING_TERTIARY = !isMobileScreen ? "15px 20px" : "15px 15px";

// radiuses
export const BOX_RADIUS_PRIMARY = !isMobileScreen ? "30px" : "20px";
export const BOX_RADIUS_SECONDARY = !isMobileScreen ? "15px" : "10px";

// fonts
export const BOX_TITLE_FONT_SIZE = "19px";
export const BOX_TITLE_FONT_WEIGHT = "500 ";
