import { useEffect, useState } from "react";
import styled from "styled-components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import { getRandomNumberInRange } from "modules/functions";
import globalConstants from "constants/globalConstants";
import Layout from "components/Layout";
import TipBox from "./TipBox";
import PageTitle from "components/text/PageTitle";
import SectionTitle from "components/text/SectionTitle";
import DashboardPostsContainer from "components/dashboard/DashboardPostsContainer";
import TutorialBox from "components/other/TutorialBox";

import referralsTipBoxIcon from "assets/img/icons/employee-engagement.svg";
import discoverTipBoxIcon from "assets/img/icons/approved.svg";
import createPostTipBoxIcon from "assets/img/icons/pc.svg";
import ReferralLinkBox from "components/referrals/ReferralLinkBox";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import DashboardContestBox from "components/dashboard/DashboardContestBox";
import IntercomMessenger from "components/chat/IntercomMessenger";

const Dashboard = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const ButtonWrap = styled(Link)`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  text-decoration: none;

  display: flex;
`;

const MessengerWrap = styled.div``;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Dashboard | Scalelup";
  }, []);

  const [tutorialBox, setTutorialBox] = useState(false);

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
      hasPost: user.hasPost,
      hasViews: user.hasViews,
    })
  );

  const { profileData, hasPost, hasViews } = useSelector(UserProperties);

  const maxTipBoxNumber = hasPost ? 2 : 3;
  const randTipBox = getRandomNumberInRange(1, maxTipBoxNumber);

  console.log({ randTipBox });

  // handle Tutorial box
  const closeBox = () => {
    setTutorialBox(false);

    localStorage.setItem("closed-discover-t-box", "y");
  };

  useEffect(() => {
    const closedBox = localStorage.getItem("closed-discover-t-box");

    if (!hasViews && !closedBox) setTutorialBox(true);
    else setTutorialBox(false);
  }, [hasViews]);

  return (
    <Layout>
      <Dashboard>
        {tutorialBox && (
          <TutorialBox
            title="Generate Coins by Viewing Posts"
            text="By regularly viewing user posts, you ensure continuous exposure for your offer, which will guarantee a steady stream of new leads."
            closeBox={closeBox}
            buttonTo="/discover"
            buttonText="Discover"
          />
        )}
        <PageTitle>Welcome, {profileData?.username}!</PageTitle>
        {randTipBox === 1 && (
          <TipBox
            title="Refer Users to Supercharge Your Business"
            text="For each referred user, you earn coins that will make your offer visible to more people."
            buttonText="Learn More"
            icon={referralsTipBoxIcon}
            to="/referrals"
          />
        )}
        {randTipBox === 2 && (
          <TipBox
            title="Find Coins by Reading Posts"
            text="Browse posts published by others to earn coins. The more coins you earn, the more people will see your offer."
            buttonText="Discover"
            icon={discoverTipBoxIcon}
            to="/discover"
          />
        )}
        {randTipBox === 3 && (
          <TipBox
            title="Create Your First Post."
            text="Increase website traffic, make new connections, and build awareness of your business."
            buttonText="Create Post"
            icon={createPostTipBoxIcon}
            to="/modify-post"
          />
        )}
        <SectionTitle>Your Posts</SectionTitle>
        <DashboardPostsContainer />
        <SectionTitle>Referrals</SectionTitle>
        <ReferralLinkBox />
        <ButtonWrap to="/referrals">
          <ButtonPrimary>Referral Program</ButtonPrimary>
        </ButtonWrap>
        {tabletScreen && (
          <>
            <SectionTitle>Contest</SectionTitle>
            <DashboardContestBox />
            <ButtonWrap to="/contest">
              <ButtonPrimary>Scalelup Contest</ButtonPrimary>
            </ButtonWrap>
          </>
        )}
      </Dashboard>
      <MessengerWrap>
        <IntercomMessenger />
      </MessengerWrap>
    </Layout>
  );
};
