import styled from "styled-components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import * as css from "styles/CssVariables.js";
import Backdrop from "components/other/Backdrop";
import MenuPublic from "./MenuPublic";
import MenuSmall from "./MenuSmall";

import scalelupLogo from "assets/img/logo/scalelup.svg";
import crossIcon from "assets/img/icons/icons8-cross-big.svg";

// Actions
import { closeHidingMenuPublic } from "store/actions";

const HidingMenuPublic = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.opened ? "0" : "-100%")};
  margin: 0;
  width: 100%;
  height: 100vh;
  padding-top: 20px;
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};

  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s ease 0s;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrap = styled.div`
  width: 140px;

  &:hover {
    cursor: pointer;
  }
`;

const CloseMenuIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const BottomSection = styled.div`
  margin-bottom: 80px;

  display: flex;
  flex-direction: column;
`;

const FooterCopyright = styled.div`
  margin-top: 14px;
  padding-left: 40px;

  color: ${css.TEXT_GREY};
  font-family: "Barlow", sans-serif;
  font-size: 16px;
`;

export default () => {
  const dispatch = useDispatch();

  const LayoutProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      hidingMenuPublic: layout.hidingMenuPublic,
    })
  );

  const { hidingMenuPublic } = useSelector(LayoutProperties);

  const closeMenu = () => {
    dispatch(closeHidingMenuPublic());
  };

  return (
    <>
      <HidingMenuPublic opened={hidingMenuPublic ? 1 : 0}>
        <TopSection>
          <Header>
            <LogoWrap style={{ color: "#fff", fontSize: "22px" }}>
              <img src={scalelupLogo} style={{ width: "100%" }} />
            </LogoWrap>
            <CloseMenuIcon onClick={closeMenu}>
              <img src={crossIcon} style={{ width: "100%" }} />
            </CloseMenuIcon>
          </Header>
          <MenuPublic closeMenu={closeMenu} />
        </TopSection>
        <BottomSection>
          <MenuSmall />
          <FooterCopyright>© 2024 Scalelup</FooterCopyright>
        </BottomSection>
      </HidingMenuPublic>
      {hidingMenuPublic && <Backdrop />}
    </>
  );
};
