import { useEffect, useState, useRef } from "react";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { v4 as uuid } from "uuid";
import * as linkify from "linkifyjs";

import MessageForm from "./MessageForm";
import { useNavigate } from "react-router-dom";

export default ({
  chatId,
  onInputFocus,
  onMessageSent,
  partnerShardNumber,
  postId,
}) => {
  const pickerRef = useRef();
  const client = useApolloClient();
  const navigate = useNavigate();

  const [text, setText] = useState("");
  const [pickerOpened, setPickerOpened] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rerenderCounter, setRerenderCounter] = useState(0);
  const [ctrlPressed, setCtrlPressed] = useState(false);

  const onSmileyClick = () => {
    setPickerOpened(!pickerOpened);
  };

  const onTextInputChange = (e) => {
    if (loading) return;

    setText(e.target.value);
  };

  const onKeyDown = (e) => {
    if (loading) return;
    console.log("onKeyDown", e.key);

    if (e.key === "Enter" && !ctrlPressed) {
      chatId ? sendChatMessage() : createNewChat();
    }
    if (e.key === "Enter" && ctrlPressed) {
      setText((prevState) => prevState + "\r\n");
    }
    if (e.key === "Control") {
      console.log("ctrl clicked");
      setCtrlPressed(true);
    }
  };

  const onKeyUp = (e) => {
    if (loading) return;
    console.log("onKeyUp", e.key);

    // if (e.key === "Enter") {
    //   console.log("enter clicked");
    //   chatId ? sendChatMessage() : createNewChat();
    // }
    if (e.key === "Control") {
      console.log("ctrl released");
      setCtrlPressed(false);
    }
  };

  console.log({ ctrlPressed });

  const onEmojiClick = (emoji) => {
    console.log("onEmojiClick emoji", emoji);

    setText((prevState) => {
      return prevState + emoji.emoji;
    });
  };

  function usePickerOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPickerOpened(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // Send message
  const SEND_MESSAGE = gql`
    mutation SendMessage($chatId: ID!, $text: String!) {
      sendMessage(chatId: $chatId, text: $text) {
        resultCode
      }
    }
  `;

  const [sendMessage] = useMutation(SEND_MESSAGE);

  const sendChatMessage = async () => {
    try {
      if (!chatId) return;
      if (loading) return;

      const textSanitized = text.trim().replace(/(\r\n|\r|\n){3,}/g, "$1$1");

      let newWarnings = [];
      setError(null);

      if (textSanitized.length === 0) newWarnings.push("text");

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }

      setWarnings([]);
      setLoading(true);

      console.log("SENDING MESSAGE");

      const result = await sendMessage({
        variables: {
          chatId,
          text: textSanitized,
        },
      });

      console.log("SEND RESULT", result);

      if (result.data.sendMessage.resultCode === 7) {
        setError(
          "You will be able to start chatting with the user once they reply to your initial message."
        );
        setLoading(false);

        return;
      }

      if (result.data.sendMessage.resultCode !== 0) {
        // Send message failed
        setError(
          "Sending the message failed. Please try again later or contact support."
        );
        setLoading(false);
        return;
      }

      setText("");
      setRerenderCounter((prevState) => prevState + 1);
      onMessageSent();

      // Add message to Apollo cache
      const randUuid = uuid();
      const createdAt = new Date();

      const chatCache = client.readQuery({
        query: gql`
          query ReadChat($id: ID!) {
            chat(id: $id) {
              id
              messages {
                id
                created_at
                chat_id
                message
                read
                post_snapshot_id
                image
              }
            }
          }
        `,
        variables: {
          id: chatId,
        },
      });

      client.writeQuery({
        query: gql`
          query Chat($chatId: ID!) {
            chat(id: $chatId) {
              id
              messages {
                id
                created_at
                chat_id
                message
                read
                post_snapshot_id
                image
              }
            }
          }
        `,
        data: {
          // Contains the data to write
          chat: {
            __typename: "ChatPayload",
            id: chatId,
            messages: [
              ...chatCache.chat.messages,
              {
                __typename: "Message",
                id: randUuid,
                created_at: createdAt,
                chat_id: chatId,
                message: textSanitized,
                read: false,
                post_snapshot_id: null,
                image: null,
              },
            ],
          },
        },
        variables: {
          chatId,
        },
      });

      setLoading(false);
    } catch (error) {
      console.log("Send message error", error);
      setLoading(false);
    }
  };

  // Create new chat
  const CREATE_CHAT = gql`
    mutation CreateChat($postId: ID!, $shardNumber: Int!, $text: String!) {
      createChat(postId: $postId, shardNumber: $shardNumber, text: $text) {
        resultCode
        chat {
          id
        }
      }
    }
  `;

  const [createChat] = useMutation(CREATE_CHAT);

  const createNewChat = async () => {
    try {
      console.log("Creating chat", postId, loading);

      if (!postId) return;
      if (loading) return;

      const textSanitized = text.trim().replace(/(\r\n|\r|\n){3,}/g, "$1$1");

      let newWarnings = [];
      setError(null);

      if (textSanitized.length === 0) newWarnings.push("text");

      if (linkify.find(textSanitized).length > 0) {
        newWarnings.push("text");
        setError(
          "Sending URL links or email addresses in the first message is not allowed."
        );

        return;
      }

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        console.log({ newWarnings });
        return;
      }

      setWarnings([]);
      setLoading(true);

      console.log("CREATING CHAT");

      const result = await createChat({
        variables: {
          postId,
          shardNumber: Number(partnerShardNumber),
          text: textSanitized,
        },
      });

      console.log("CREATE RESULT", result);

      if (result.data.createChat.resultCode === 9) {
        console.log("Too many chat creations");

        setError(
          "You can reach out to a maximum of two new users per day through messages."
        );
        setLoading(false);

        return;
      }

      if (!result.data.createChat.chat) {
        console.log("Create chat failed");

        setError(
          "Sending the message failed. Please try again later or contact support."
        );
        setLoading(false);

        return;
      }

      // chat created successfully
      localStorage.setItem("ch_created_from", postId);
      navigate(`/chat?id=${result.data.createChat.chat.id}`);

      setLoading(false);
    } catch (error) {
      console.log("Create chat error", error);
      setLoading(false);
    }
  };

  return (
    <MessageForm
      key={rerenderCounter}
      text={text}
      onTextInputChange={onTextInputChange}
      pickerRef={pickerRef}
      onSmileyClick={onSmileyClick}
      pickerOpened={pickerOpened}
      onEmojiClick={onEmojiClick}
      usePickerOutsideClick={usePickerOutsideClick}
      onSendClick={chatId ? sendChatMessage : createNewChat}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      loading={loading}
      onInputFocus={onInputFocus}
      error={error}
    />
  );
};
