import React from "react";
import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const Button = styled.div`
  box-sizing: border-box;
  height: 42px;
  padding: 0 30px;
  min-width: ${(props) => (props.minwidth ? props.minwidth : "auto")};

  background: #fff;
  border-radius: 21px;
  border: 0;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: ${css.DARK_BLUE};
  white-space: nowrap;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    !props.disabled &&
    `&:hover {
    cursor: pointer;

    color: #fff;

    background: ${css.BRAND_GREEN};
  }`}
`;

export default (props) => {
  const { onClick, parameters, id, children, disabled, minWidth } = props;
  return (
    <Button
      onClick={() => {
        if (disabled || !onClick) return;
        onClick(parameters);
      }}
      id={id}
      disabled={disabled}
      minwidth={minWidth}
    >
      {children}
    </Button>
  );
};
