import styled from "styled-components";
import { useEffect } from "react";

import globalConstants from "constants/globalConstants";
import * as css from "styles/CssVariables.js";
import coinsIcon from "assets/img/icons/coins-icon.svg";
import MoonLoader from "react-spinners/MoonLoader";
import { useNavigate } from "react-router-dom";

const BalanceBox = styled.div`
  height: 60px;
  padding: 0px 25px;
  min-width: 118px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: 30px;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media (max-width: ${globalConstants.switchMobile}) {
    height: 48px;
    padding: 0px 20px;

    border-radius: 24px;

    gap: 8px;
  }

  &:hover {
    background: #364e65;
    cursor: pointer;
  }
`;

const BalanceIcon = styled.div``;

const Icon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
`;

const Balance = styled.div`
  font-size: 18px;
  font-weight: 500;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 16px;
  }
`;

const Loading = styled.div``;

export default ({ data, subscribeToUpdates }) => {
  const navigate = useNavigate();

  useEffect(() => {
    subscribeToUpdates();
  }, []);

  return (
    <BalanceBox onClick={() => navigate("/wallet")}>
      {data?.balance && (
        <>
          <BalanceIcon>
            <Icon>
              <img src={coinsIcon} style={{ width: "100%" }} />
            </Icon>
          </BalanceIcon>
          <Balance>{data.balance.balance}</Balance>
        </>
      )}
      {!data?.balance && (
        <Loading>
          <MoonLoader color="rgba(255,255,255,1)" size={18} />
        </Loading>
      )}
    </BalanceBox>
  );
};
