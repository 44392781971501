import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import globalConstants from "constants/globalConstants";
import LoadingPage from "components/other/LoadingPage";
import TextLink from "components/text/TextLink";

const DataDeletionStatus = styled.div`
  position: relative;
  width: 100%;
  padding: 120px 15vw 30px;
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
  line-height: 140%;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 90px 6vw 30px;
  }
`;

const H2Title = styled.h2`
  font-size: 30px;
  font-weight: 500;
  line-height: 115%;
`;

const CodeInfo = styled.div`
  margin-bottom: 20px;

  font-style: italic;
`;

const Paragraph = styled.div``;

export default () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  console.log({ code });

  if (!code) {
    window.location = "/";
    return;
  }

  const DATA_DELETION_STATUS = gql`
    query DataDeletionStatus($code: String!) {
      dataDeletionStatus(code: $code) {
        resultCode
      }
    }
  `;

  const { loading, data, error } = useQuery(DATA_DELETION_STATUS, {
    variables: { code },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("data deletion status error", error);
    //captureException(error);
  }

  console.log("data", data);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <DataDeletionStatus>
          <H2Title>Data Deletion Request Status</H2Title>
          <CodeInfo>Request code: {code}</CodeInfo>
          <Paragraph>
            Your data has been deleted from the Scalelup platform database. For
            more information, please refer to our{" "}
            <TextLink to="/privacy-policy">Privacy Policy</TextLink> or contact
            us at privacy@scalelup.com.
          </Paragraph>
        </DataDeletionStatus>
      )}
    </>
  );
};
