import styled from "styled-components";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import HeaderPublic from "components/HeaderPublic";

import errorIcon from "assets/img/icons/icons8-error.svg";

const RouterError = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 500px;
  padding: 0;
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
  color: #fff;
`;

const ErrorContent = styled.div`
  padding: 0 10vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const ErrorIcon = styled.div`
  width: 80px;
  min-width: 80px;
  height: 80px;
`;

const ErrorTitle = styled.div`
  margin-top: 20px;

  font-size: 48px;
`;

const ErrorText = styled.div`
  margin-top: 10px;

  font-size: 18px;
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${css.LIGHT_BLUE};
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "404 Error | Scalelup";
  }, []);

  let error = useRouteError();
  console.log({ error });

  return (
    <>
      <HeaderPublic />
      <RouterError>
        <ErrorContent>
          <ErrorIcon>
            <img src={errorIcon} alt="Error" style={{ width: "100%" }} />
          </ErrorIcon>
          <ErrorTitle>
            {error?.status} {error?.statusText}
          </ErrorTitle>
          <ErrorText>
            Sorry, the requested URL was not found. Please go back to the{" "}
            <TextLink to="/">homepage</TextLink>.
          </ErrorText>
        </ErrorContent>
      </RouterError>
    </>
  );
};
