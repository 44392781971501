import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { AUTH_ID, AUTH_TOKEN, AUTH_WS_TOKEN, SHARD_NUMBER } from "constants";
import LoadingPage from "components/other/LoadingPage";

export default () => {
  console.log("superloiginconteinaer");
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const destination = searchParams.get("destination");

  // if (localStorage.getItem(AUTH_TOKEN)) {
  //   console.log("removing tokens");
  //   // user already logged in, remove tokens
  //   localStorage.removeItem(AUTH_ID);
  //   localStorage.removeItem(AUTH_TOKEN);
  //   localStorage.removeItem(AUTH_WS_TOKEN);
  //   localStorage.removeItem(SHARD_NUMBER);
  // }

  console.log({ code });

  if (!code) {
    window.location = "/?message=magicLoginFailed";
    return;
  }

  const SUPER_LOGIN = gql`
    mutation SuperLogin($code: String!) {
      superLogin(code: $code) {
        resultCode
        id
        token
        wsToken
        shardNumber
      }
    }
  `;

  const [superLogin] = useMutation(SUPER_LOGIN);

  const processLogin = async () => {
    console.log("processing SUPER LOGIN");
    try {
      const result = await superLogin({
        variables: { code },
      });

      const { resultCode, id, token, wsToken, shardNumber } =
        result.data.superLogin;

      console.log({ resultCode, token, wsToken, shardNumber });

      if (resultCode !== 0 || !token) {
        window.location = "/?message=magicLoginFailed";
        return;
      }

      // login successfull, redirect to account
      console.log("saving localstorage");
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      console.log("localstorage saved");

      if (destination && destination !== "")
        window.location = "/" + destination;
      else window.location = "/home";
    } catch (error) {
      //captureException(error);
      console.log("superLogin error", error);
      return;
    }
  };

  useEffect(() => {
    console.log("PROCESSING LOGIN");
    processLogin();
  }, []);

  return <LoadingPage />;
};
