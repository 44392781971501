import styled from "styled-components";

import globalConstants from "constants/globalConstants";
import MenuFooterItem from "./MenuFooterItem";

const MenuSmall = styled.div`
  padding-left: 40px;

  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export default () => {
  return (
    <MenuSmall>
      <MenuFooterItem to="/">Home</MenuFooterItem>
      <MenuFooterItem to="https://help.scalelup.com/" target="_blank">
        Support
      </MenuFooterItem>
      <MenuFooterItem to="/terms">Terms</MenuFooterItem>
      <MenuFooterItem to="/privacy-policy">Privacy Policy</MenuFooterItem>
      <div
        onClick={() => {
          window.cookiehub.openSettings();
        }}
      >
        <MenuFooterItem to="#">Cookie Settings</MenuFooterItem>
      </div>
    </MenuSmall>
  );
};
