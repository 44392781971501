import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useSubscription } from "@apollo/client";
import { useMediaQuery } from "react-responsive";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
  useNavigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom";
// import CookieBot from "react-cookiebot";

import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

// Routes
import { authProtectedRoutes, publicRoutes } from "routes";

// Middleware
import { AuthMiddleware } from "routes/route";

import * as css from "styles/CssVariables.js";
import { REF_CODE, MAINTENANCE_MODE, AUTH_ID, ADMIN_USER_ID } from "constants";
import globalConstants from "constants/globalConstants";
import { fetchWithTimeout, getIp } from "modules/functions";
import LoadingPage from "components/other/LoadingPage";
import HeaderPublic from "components/HeaderPublic";
import Header from "components/Header";
import LoginContainer from "components/account/LoginContainer";
import SignupContainer from "components/account/SignupContainer";
import ForgotPasswordContainer from "components/account/ForgotPasswordContainer";
import ModalWrap from "components/other/ModalWrap";
import Backdrop from "components/other/Backdrop";
import Footer from "./Footer";
import MessageWindow from "./other/MessageWindow";
import ScrollToTop from "components/other/ScrollToTop";
import ErrorBoundary from "./other/ErrorBoundary";

import sorryIcon from "assets/img/icons/icons8-sorry.svg";
import infoIcon from "assets/img/icons/icons8-info-solid.svg";
import successWhiteIcon from "assets/img/icons/icons8-success-white.svg";

// Actions
import {
  closeHidingMenu,
  closeHidingMenuPublic,
  closeModal,
  openMessageModal,
  closeMessageModal,
  saveIpAddress,
  saveProfileData,
  updateUnreadMessages,
  updateProgressPosts,
  updateProgressViews,
} from "store/actions";
import Maintenance from "./Maintenance";

const AppBody = styled.div`
  margin: 0;
  padding: 0;
  /* background: ${css.PAGE_BACKGROUND}; */

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled.div`
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  font-family: "Barlow", sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;

  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 0 15px;
  }
`;

// define Router
let router;

const userId = localStorage.getItem(AUTH_ID);

if (MAINTENANCE_MODE && userId !== ADMIN_USER_ID) {
  router = createBrowserRouter([
    {
      path: "*",
      element: <Maintenance />,
      errorElement: <ErrorBoundary />,
    },
  ]);
} else {
  router = createBrowserRouter([
    {
      path: "/",
      element: <AppContent />,
      children: [
        ...publicRoutes.map((route, idx) => ({
          path: route.path,
          element: (
            <>
              <HeaderPublic />
              {route.component}
            </>
          ),
        })),
        ...authProtectedRoutes.map((route, idx) => ({
          path: route.path,
          element: (
            <AuthMiddleware>
              <Header />
              <Content>{route.component}</Content>
            </AuthMiddleware>
          ),
        })),
      ],
      errorElement: <ErrorBoundary />,
    },
  ]);
}

function AppContent() {
  // Suppres console logs in production
  // if (process.env.NODE_ENV === "production") {
  //   console.log = function no_console() {};
  //   console.warn = function no_console() {};
  // }

  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  // const headerPublicRef = useRef();

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  const [ipAddress, setIpAddress] = useState(null);

  const LayoutProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      hidingMenu: layout.hidingMenu,
      hidingMenuPublic: layout.hidingMenuPublic,
      loginModal: layout.loginModal,
      signupModal: layout.signupModal,
      messageModal: layout.messageModal,
      messageModalData: layout.messageModalData,
      forgotModal: layout.forgotModal,
    })
  );

  const {
    hidingMenu,
    hidingMenuPublic,
    loginModal,
    signupModal,
    messageModal,
    messageModalData,
    forgotModal,
  } = useSelector(LayoutProperties);

  // save referral code
  useEffect(() => {
    const refCode = searchParams.get("r") ? searchParams.get("r") : null;

    if (refCode) {
      localStorage.setItem(REF_CODE, refCode);
    }
  }, [searchParams]);

  // save UTM parameters
  useEffect(() => {
    if (searchParams.get("utm_source"))
      localStorage.setItem("utmSource", searchParams.get("utm_source"));

    if (searchParams.get("utm_medium"))
      localStorage.setItem("utmMedium", searchParams.get("utm_medium"));

    if (searchParams.get("utm_campaign"))
      localStorage.setItem("utmCampaign", searchParams.get("utm_campaign"));

    if (searchParams.get("utm_banner"))
      localStorage.setItem("utmBanner", searchParams.get("utm_banner"));
  }, []);

  const onCloseModal = () => {
    dispatch(closeModal());
  };

  const openMessage = (data) => {
    dispatch(openMessageModal(data));
  };

  const closeMessage = () => {
    dispatch(closeMessageModal());
  };

  useEffect(() => {
    if (hidingMenu) dispatch(closeHidingMenu());
    if (hidingMenuPublic) dispatch(closeHidingMenuPublic());

    if (searchParams.get("message")) {
      // app message received, open modal
      const message = searchParams.get("message");

      if (message === "accountEmailVerificationExpiredLink") {
        openMessage({
          title: "Link Invalid",
          text: "The email verification link is invalid or has expired.",
          icon: infoIcon,
        });
      }

      if (message === "accountEmailVerificationFailed") {
        openMessage({
          title: "Verification Error",
          text: "We apologize, an unexpected error occurred while verifying your email. Our technicians have been notified, and we will fix the problem as soon as possible. For more information, you can contact us.",
          icon: sorryIcon,
        });
      }

      if (message === "emailVerifiedSuccessfully") {
        openMessage({
          title: "Email Verified",
          text: "The email address has been successfully verified and added to your account.",
          icon: successWhiteIcon,
        });
      }

      if (message === "resetPasswordExpiredLink") {
        openMessage({
          title: "Link Invalid",
          text: "The password reset link is invalid or has expired. Please try requesting a password reset again.",
          icon: infoIcon,
        });
      }

      if (message === "resetPasswordLoginFailed") {
        openMessage({
          title: "Reset Password Error",
          text: "We apologize, an unexpected error occurred while logging you into your account. Our technicians have been notified, and we will fix the problem as soon as possible. For more information, you can contact us.",
          icon: sorryIcon,
        });
      }

      if (message === "magicLoginExpired") {
        openMessage({
          title: "Link Invalid",
          text: "The link is invalid or has expired. Please log in to your account.",
          icon: infoIcon,
        });
      }

      if (message === "magicLoginFailed") {
        openMessage({
          title: "Login Error",
          text: "We apologize, an unexpected error occurred while logging you into your account. Our technicians have been notified, and we will fix the problem as soon as possible.",
          icon: sorryIcon,
        });
      }
    }
  }, [location, searchParams]);

  // get IP address
  useEffect(() => {
    const fetchIp = async () => {
      let ip = null;

      await fetchWithTimeout(5000, getIp())
        .then((response) => {
          ip = response;
        })
        .catch(() => {});

      if (ip) {
        setIpAddress(ip.ip);
        dispatch(saveIpAddress(ip.ip));
      }
    };

    fetchIp();
  }, []);

  // get User profile data
  const GET_USER_PROFILE_DATA = gql`
    query UserProfileData {
      userProfileData {
        id
        created_at
        email
        username
        profile_image
        referral_code
        posts_aggregate {
          aggregate {
            count
          }
        }
        views_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_USER_PROFILE_DATA);

  if (error) {
    console.log("error", error);
  }
  console.log({ data });

  // save Profile data to redux
  useEffect(() => {
    if (error) {
      dispatch(
        saveProfileData({
          id: null,
          created_at: null,
          email: null,
          username: null,
          profileImage: null,
          referralCode: null,
        })
      );
    }

    if (!loading && data) {
      if (data.userProfileData) {
        const {
          id,
          created_at,
          email,
          username,
          profile_image,
          referral_code,
          posts_aggregate,
          views_aggregate,
        } = data.userProfileData;

        dispatch(
          saveProfileData({
            id,
            created: new Date(created_at).getTime(),
            email,
            username,
            profileImage: profile_image,
            referralCode: referral_code,
          })
        );

        dispatch(updateProgressPosts(posts_aggregate.aggregate.count > 0));
        dispatch(updateProgressViews(views_aggregate.aggregate.count > 0));
      }
    }
  }, [loading]);

  const userId = localStorage.getItem(AUTH_ID);
  const sn = "s" + localStorage.getItem("sn");

  const UNREAD_SUBSCRIPTION = gql`
    subscription onUnreadUpdate($userId: uuid!) {
      ${sn} {
        chats_aggregate(
          where: {
            user_id: { _eq: $userId }
            unread_messages: { _eq: true }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `;

  const { data: unreadData } = useSubscription(UNREAD_SUBSCRIPTION, {
    variables: { userId },
  });

  useEffect(() => {
    if (unreadData) {
      console.log("udpated", unreadData);
      if (unreadData[sn].chats_aggregate.aggregate.count > 0) {
        dispatch(updateUnreadMessages(true));
      } else {
        dispatch(updateUnreadMessages(false));
      }
    }
  }, [unreadData]);

  return (
    <AppBody>
      <ScrollToTop />
      <Body>
        {loading && <LoadingPage />}
        {!loading && (
          <>
            {loginModal && (
              <ModalWrap>
                <Backdrop
                  onClick={onCloseModal}
                  background="rgba(0, 0, 0, 0.7)"
                />
                <LoginContainer
                  handleModalClose={onCloseModal}
                  openErrorWindow={() =>
                    openMessage({
                      title: "Login Error",
                      text: "We apologize, an unexpected error occurred during login. Our technicians have been notified, and we will fix the problem as soon as possible. For more information, you can contact us.",
                      icon: sorryIcon,
                    })
                  }
                  ipAddress={ipAddress}
                />
              </ModalWrap>
            )}
            {signupModal && (
              <ModalWrap>
                <Backdrop
                  onClick={onCloseModal}
                  background="rgba(0, 0, 0, 0.7)"
                />
                <SignupContainer
                  handleModalClose={onCloseModal}
                  openErrorWindow={() =>
                    openMessage({
                      title: "Sign Up Error",
                      text: "We apologize, an unexpected error occurred while creating your account. Our technicians have been notified, and we will fix the problem as soon as possible. For more information, you can contact us.",
                      icon: sorryIcon,
                    })
                  }
                  ipAddress={ipAddress}
                />
              </ModalWrap>
            )}
            {forgotModal && (
              <ModalWrap>
                <Backdrop
                  onClick={onCloseModal}
                  background="rgba(0, 0, 0, 0.7)"
                />
                <ForgotPasswordContainer
                  handleModalClose={onCloseModal}
                  openErrorWindow={() =>
                    openMessage({
                      title: "Password Reset Error",
                      text: "We apologize, an unexpected error occurred while sending your a password reset email. Our technicians have been notified, and we will fix the problem as soon as possible. For more information, you can contact us.",
                      icon: sorryIcon,
                    })
                  }
                  ipAddress={ipAddress}
                />
              </ModalWrap>
            )}
            {messageModal && (
              <ModalWrap>
                <Backdrop
                  onClick={closeMessage}
                  background="rgba(0, 0, 0, 0.7)"
                />
                <MessageWindow
                  handleModalClose={closeMessage}
                  title={messageModalData.title}
                  icon={messageModalData.icon}
                >
                  {messageModalData.text}
                </MessageWindow>
              </ModalWrap>
            )}
            <Outlet />
            {!tabletScreen && <Footer />}
          </>
        )}
      </Body>
    </AppBody>
  );
}

//export default App;
export default function App() {
  return (
    <div>
      <RouterProvider router={router} />
      {/* <CookieBot domainGroupId="e56e68ee-7040-4f37-948e-766f2730d1b4" /> */}
    </div>
  );
}
