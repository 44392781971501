import { useEffect, useRef } from "react";
import styled from "styled-components";
import EmojiPicker from "emoji-picker-react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { AWS_USER_UPLOAD_URL, AWS_PROFILE_IMAGES_URL } from "constants";
import Layout from "components/Layout";
import PageTitle from "components/text/PageTitle";
import InputWithLabel from "components/forms/InputWithLabel";
import InputText from "components/forms/InputText";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import Backdrop from "components/other/Backdrop";
import ModalWindow from "components/other/ModalWindow";
import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import SidePostPreview from "./SidePostPreview";
import TargetingForm from "./TargetingForm";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import SmallSpinner from "components/other/SmallSpinner";
import RoundActionIcon from "components/buttons/RoundActionIcon";
import ButtonWhite from "components/buttons/ButtonWhite";
import TutorialBox from "components/other/TutorialBox";
import Dropdown from "components/forms/Dropdown";
import DropdownSelection from "components/forms/DropdownSelection";
import SelectionItem from "components/forms/SelectionItem";

import smileyIcon from "assets/img/icons/icons8-smiley.svg";
import aiEditIcon from "assets/img/icons/ai-edit-icon.svg";
import tipIcon from "assets/img/icons/icons8-info.svg";
import coinsIcon from "assets/img/icons/coins-icon.svg";
import binIcon from "assets/img/icons/bin-icon.svg";
import binWhiteIcon from "assets/img/icons/bin-icon-white.svg";
import returnIcon from "assets/img/icons/return-icon.svg";

const CreatePost = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackIconWrap = styled.div``;

const FormBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormHeader = styled.div`
  display: flex;
  gap: 8px;
`;

const AvatarIconWrap = styled.div`
  width: 56px;
  min-width: 56px;
  aspect-ratio: 1;
`;

const AvatarIcon = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const FormHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  padding-top: 9px;

  font-size: 18px;
  font-weight: 500;
`;

const PostInfo = styled.div`
  padding-top: 2px;

  font-size: 14px;
  color: ${css.TEXT_GREY};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex: 1;
`;

const TextInputBox = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  background: ${css.DARK_BLUE};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  padding: 25px;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 15px;
  color: #fff;
  line-height: 125%;

  background: ${css.DARK_BLUE};
  border: 0px;
  border-radius: ${css.BOX_RADIUS_SECONDARY};
  resize: none;
`;

const TextInputFooter = styled.div`
  height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CharacterCounter = styled.div`
  position: relative;
  margin-left: 25px;
`;

const EmptyCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: rgb(255, 255, 255, 0.07);
`;

const OuterCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: ${(props) => (props.atmax ? css.ERROR_RED : css.BRAND_INDIGO)};
  mask: conic-gradient(
    from 0deg,
    #0000,
    #000 1deg ${(props) => props.angle}deg,
    #0000 calc(${(props) => props.angle}deg + 1deg)
  );
`;

const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  transform: translate(-50%, -50%);

  background: ${css.DARK_BOX_BACKGROUND};
  border-radius: 50%;
`;

const RightControls = styled.div`
  padding: 0 18px 0 25px;

  display: flex;
  align-items: center;
`;

const TextAreaIconCircle = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;

  border-radius: 18px;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgb(255, 255, 255, 0.07);
    cursor: pointer;
  }
`;

const TextAreaIcon = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.width};

  &:hover {
    cursor: pointer;
  }
`;

const FormBottom = styled.div`
  position: relative;
  width: 100%;
`;

const PickerWrap = styled.div`
  position: absolute;
  top: ${css.VERTICAL_BOX_MARGIN};
  right: 0;
  z-index: 1;
`;

const FileInput = styled.input`
  width: 100%;
  height: 42px;
  box-sizing: border-box;

  border: 0px;
  border-radius: 15px;

  background: ${css.DARK_BOX_BACKGROUND};

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;

  &::file-selector-button {
    margin: 7px 14px 0 10px;
    height: 28px;
    padding: 0 15px;

    border: 0;
    border-radius: 14px;
    cursor: pointer;
    background: ${css.BRAND_YELLOW};

    transition: 0.2s;

    font-family: "Barlow";
    font-size: 13px;
    font-weight: 500;
  }

  &::file-selector-button:hover {
    color: #fff;

    background: ${css.BRAND_GREEN};
  }
`;

const ImagePreviewBox = styled.div`
  position: relative;
`;

const ImagePreview = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  margin-bottom: 6px;

  display: flex;
`;

const ImagePreviewImage = styled.img`
  max-width: 100%;
  max-height: 320px;
  object-fit: contain;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const DeleteImageOverlay = styled.div`
  position: absolute;
  bottom: 16px;
  right: 10px;
`;

const DeleteImageIcon = styled.div`
  width: 16px;
  height: 16px;

  transition: 0.2s;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const DeleteImageCircle = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;

  border-radius: 16px;
  background: ${css.BRAND_YELLOW};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    background: ${css.ERROR_RED};
  }

  &:hover ${DeleteImageIcon} {
    background-image: url(${(props) => props.iconhover});
  }
`;

const Warning = styled.div`
  margin-top: 7px;

  font-size: 13px;
  font-weight: 500;
  color: ${css.ERROR_RED};
`;

const TargetingInputSection = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const TargetingButtonWrap = styled.div``;

const TargetingWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);

  width: 100%;
  max-width: 520px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const CpiBox = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  padding: ${css.BOX_PADDING_SECONDARY};

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
`;

const CpiBoxTitle = styled.div`
  display: flex;
  align-items: center;
`;

const CpiBoxTitleText = styled.div`
  font-weight: 600;
`;

const TipIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;

  margin-left: 6px;

  &:hover {
    cursor: help;
  }
`;

const CpiBoxCpi = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  display: flex;
  align-items: center;
`;

const CpiBoxCpiIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const CpiBoxCpiText = styled.div`
  padding-left: 8px;

  font-weight: 600;
`;

const CpiBoxNote = styled.div`
  margin-top: 6px;

  font-size: 13px;
  color: ${css.TEXT_GREY};
`;

const CpiBoxButton = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  min-height: 42px;

  display: flex;
  align-items: center;
`;

const CpiBoxForm = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  display: flex;
  align-items: center;
`;

const CpiBoxFormInput = styled.div`
  max-width: 80px;
`;

const CpiBoxFormButton = styled.div`
  padding-left: 8px;
`;

const CpiBoxCpiSet = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  min-height: 42px;

  display: flex;
  align-items: center;
`;

const CpiBoxCpiSetText = styled.div`
  padding-right: 8px;

  line-height: 1;
`;

const PostButton = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  margin-bottom: 6px;
`;

const PromptModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);

  width: 100%;
  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const ConfirmTemplateWrap = styled.div`
  margin-top: 60px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmTemplateText = styled.div``;

const ConfirmTemplateButtons = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_XLARGE};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${css.BUTTONS_GAP};
`;

const DropdownWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 150px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const ViewIntervalSelectionItem = styled.div``;

export default ({
  form,
  text,
  onTextInputChange,
  onSmileyClick,
  pickerOpened,
  onEmojiClick,
  pickerRef,
  usePickerOutsideClick,
  onImageInputChange,
  image,
  onImageLinkInputChange,
  imageLink,
  warnings,
  openTargeting,
  closeTargeting,
  targetingOpened,
  allCountries,
  loading,
  countries,
  updateCountries,
  cpiEstimate,
  openCpiForm,
  closeCpiForm,
  cpiFormOpened,
  onCpiInputChange,
  cpi,
  removeImage,
  imageWarning,
  onPostSubmit,
  submitLoading,
  submitError,
  userProfileData,
  name,
  onNameInputChange,
  getPostTemplate,
  confirmTemplate,
  closeConfirmModal,
  addTemplateText,
  blockModal,
  closeBlockModal,
  confirmLeaveForm,
  tutorialBox,
  closeBox,
  toggleViewIntervalSelection,
  viewIntervalSelectionOpened,
  viewInterval,
  onViewIntervalSelect,
}) => {
  const textArea = useRef();
  const navigate = useNavigate();
  const viewIntervalDropdownRef = useRef();
  const viewIntervalSelectionRef = useRef();

  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  // set page Title
  useEffect(() => {
    document.title = "Create Post | Scalelup";
  }, []);

  // handle Textarea height
  useEffect(() => {
    if (!loading) {
      textArea.current.style.height = "105px";

      const requestedHeight = Math.max(textArea.current.scrollHeight, 150);

      textArea.current.style.height = `${Math.min(requestedHeight, 600)}px`;
    }
  }, [text, loading]);

  const textMaximum = 500;
  const counterAngle = (360 / textMaximum) * text.length;

  usePickerOutsideClick(pickerRef);

  // prepare Image preview URL address
  const previewLink = AWS_USER_UPLOAD_URL + image;

  // prepare Country Targeting input field content
  let countriesNamesList = "";

  const displayCountries = mobileScreen ? 2 : 4;

  for (let i = 0; i < displayCountries; i++) {
    if (countries[i]) countriesNamesList += countries[i] + ", ";
  }

  let countryInputText = countriesNamesList.slice(0, -2);

  if (countries.length > displayCountries) {
    const remaining = countries.length - displayCountries;

    countryInputText += " (+" + remaining + ")";
  }

  if (allCountries.length === countries.length)
    countryInputText = "All countries";

  if (countries.length === 0) countryInputText = "Select at least one country";

  // prepare Title and Button labels
  const titleText = form === "create" ? "Create New Post" : "Edit Post";
  const buttonText = form === "create" ? "Post" : "Submit";

  // prepare Profile picture
  const profilePictureURL =
    AWS_PROFILE_IMAGES_URL + userProfileData?.profile_image;

  console.log({ allCountries });

  return (
    <Layout
      sideContent={
        <SidePostPreview
          avatar={profilePictureURL}
          username={userProfileData?.username}
          text={text}
          image={image}
          imageLink={imageLink}
        />
      }
    >
      <CreatePost>
        {tutorialBox && (
          <TutorialBox
            title="Create Your First Post"
            text="Start by creating a post. Get inspired by clicking on the magic pencil icon. You can also include a link to your website. Users can visit your site or contact you via private message."
            closeBox={closeBox}
          />
        )}
        <Header>
          <PageTitle>{titleText}</PageTitle>
          <BackIconWrap>
            <RoundActionIcon
              icon={returnIcon}
              iconSize="16px"
              iconAlt="Return"
              onClick={() => navigate("/posts")}
            />
          </BackIconWrap>
        </Header>
        <FormBox>
          {loading && <BoxLoadingSpinner />}
          {!loading && (
            <>
              <FormHeader>
                <AvatarIconWrap>
                  <AvatarIcon
                    src={profilePictureURL}
                    style={{ width: "100%" }}
                  />
                </AvatarIconWrap>
                <FormHeaderContent>
                  <UserName>{userProfileData?.username}</UserName>
                  <PostInfo>Post to Anyone</PostInfo>
                </FormHeaderContent>
              </FormHeader>
              <Content>
                <TextInputBox>
                  <TextArea
                    ref={textArea}
                    name="text"
                    value={text}
                    placeholder="Share your business offer or anything you want..."
                    maxLength={textMaximum}
                    onChange={onTextInputChange}
                  />
                  <TextInputFooter>
                    <CharacterCounter>
                      <EmptyCircle />
                      <OuterCircle
                        angle={counterAngle}
                        atmax={text.length === textMaximum ? 1 : 0}
                      />
                      <InnerCircle />
                    </CharacterCounter>
                    <RightControls>
                      <TextAreaIconCircle onClick={onSmileyClick}>
                        <TextAreaIcon width="20px">
                          <img src={smileyIcon} style={{ width: "100%" }} />
                        </TextAreaIcon>
                      </TextAreaIconCircle>
                      <TextAreaIconCircle
                        onClick={getPostTemplate}
                        data-tooltip-id="templateTip"
                        data-tooltip-content="Click to insert a sample template text that will help you start creating an engaging post."
                        data-tooltip-place="top"
                        data-tooltip-delay-show={500}
                      >
                        <TextAreaIcon width="18px">
                          <img src={aiEditIcon} style={{ width: "100%" }} />
                        </TextAreaIcon>
                      </TextAreaIconCircle>
                      <Tooltip id="templateTip" className="tooltip" />
                    </RightControls>
                  </TextInputFooter>
                </TextInputBox>
                <FormBottom>
                  <PickerWrap ref={pickerRef}>
                    <EmojiPicker
                      open={pickerOpened}
                      onEmojiClick={onEmojiClick}
                      autoFocusSearch={false}
                      theme="dark"
                      width={280}
                      height={310}
                    />
                  </PickerWrap>
                </FormBottom>
                {warnings.includes("text") && (
                  <Warning>
                    The post must be at least 20 characters long.
                  </Warning>
                )}
                <InputWithLabel
                  label="Add Image (optional)"
                  width="100%"
                  marginTop={css.VERTICAL_BOX_MARGIN_MEDIUM}
                >
                  <FileInput
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={onImageInputChange}
                  />
                </InputWithLabel>
                {imageWarning && (
                  <Warning>
                    The selected file is too large. The maximum allowed size is
                    4000 kB.
                  </Warning>
                )}
                {image && (
                  <ImagePreviewBox>
                    <ImagePreview>
                      <ImagePreviewImage src={previewLink} />
                    </ImagePreview>
                    <DeleteImageOverlay>
                      <DeleteImageCircle
                        iconhover={binWhiteIcon}
                        onClick={removeImage}
                      >
                        <DeleteImageIcon icon={binIcon} />
                      </DeleteImageCircle>
                    </DeleteImageOverlay>
                  </ImagePreviewBox>
                )}
                <InputWithLabel
                  label="Image Destination Link (optional)"
                  width="100%"
                  marginTop={css.VERTICAL_BOX_MARGIN_MEDIUM}
                  tip="Enter the URL to which the user will be directed when they click on your image."
                >
                  <InputText
                    type="text"
                    name="link"
                    value={imageLink}
                    placeholder="https://..."
                    onChange={onImageLinkInputChange}
                    maxLength={400}
                    warning={warnings.includes("link")}
                  />
                </InputWithLabel>
                {warnings.includes("imageLink") && (
                  <Warning>
                    The entered URL is invalid. The address should start with
                    https://
                  </Warning>
                )}
                <InputWithLabel
                  label="Country Targeting"
                  width="100%"
                  marginTop={css.VERTICAL_BOX_MARGIN_MEDIUM}
                  tip="Here you can choose the countries where your post will be displayed."
                >
                  <TargetingInputSection>
                    <InputText
                      type="text"
                      name="link"
                      value={countryInputText}
                      warning={warnings.includes("link")}
                      readOnly={true}
                    />
                    <TargetingButtonWrap>
                      <ButtonPrimarySmall onClick={openTargeting}>
                        Change
                      </ButtonPrimarySmall>
                    </TargetingButtonWrap>
                  </TargetingInputSection>
                </InputWithLabel>
                {warnings.includes("targeting") && (
                  <Warning>Select at least one target country.</Warning>
                )}
                <InputWithLabel
                  label="View Interval"
                  width="100%"
                  marginTop={css.VERTICAL_BOX_MARGIN_MEDIUM}
                  tip="<b>Coming soon</b> - this feature is not yet available. The display interval is set to 1 day by default.<br><br>Select how often the post can be shown to the same user. A shorter interval means the user will see your post more frequently, and vice versa."
                >
                  <DropdownWrap>
                    <Dropdown
                      onClick={toggleViewIntervalSelection}
                      dropdownRef={viewIntervalDropdownRef}
                      disabled
                    >
                      <ViewIntervalSelectionItem>
                        {viewInterval} day{viewInterval > 1 ? "s" : ""}
                      </ViewIntervalSelectionItem>
                    </Dropdown>
                    {viewIntervalSelectionOpened && (
                      <DropdownSelection
                        width="150px"
                        top="48px"
                        selectionRef={viewIntervalSelectionRef}
                      >
                        <SelectionItem onClick={() => onViewIntervalSelect(1)}>
                          <ViewIntervalSelectionItem>
                            1 day
                          </ViewIntervalSelectionItem>
                        </SelectionItem>
                        <SelectionItem onClick={() => onViewIntervalSelect(3)}>
                          <ViewIntervalSelectionItem>
                            3 days
                          </ViewIntervalSelectionItem>
                        </SelectionItem>
                        <SelectionItem onClick={() => onViewIntervalSelect(9)}>
                          <ViewIntervalSelectionItem>
                            9 days
                          </ViewIntervalSelectionItem>
                        </SelectionItem>
                        <SelectionItem onClick={() => onViewIntervalSelect(27)}>
                          <ViewIntervalSelectionItem>
                            27 days
                          </ViewIntervalSelectionItem>
                        </SelectionItem>
                      </DropdownSelection>
                    )}
                  </DropdownWrap>
                </InputWithLabel>
                <CpiBox>
                  <CpiBoxTitle>
                    <CpiBoxTitleText>CPI (Cost per Impression)</CpiBoxTitleText>
                    <TipIcon
                      data-tooltip-id="cpiTip"
                      data-tooltip-html="<b>This is just an estimate. Actual costs may vary. To have better control, set a maximum CPI.</b><br><br>The cost per impression determines how many coins will be deducted from your balance when a user views your post. The price depends on the user's location and the current amount of rotating posts. The cost per impression is constantly changing. If you want better control over your coin consumption, set a maximum price per impression that will not be exceeded."
                      data-tooltip-place="top"
                      data-tooltip-delay-show={500}
                    >
                      <img
                        src={tipIcon}
                        style={{ width: "100%", paddingTop: "1px" }}
                      />
                    </TipIcon>
                    <Tooltip id="cpiTip" className="tooltip" />
                  </CpiBoxTitle>
                  <CpiBoxCpi>
                    <CpiBoxCpiIcon>
                      <img
                        src={coinsIcon}
                        style={{ width: "100%", paddingTop: "1px" }}
                      />
                    </CpiBoxCpiIcon>
                    <CpiBoxCpiText>
                      {cpiEstimate.min === cpiEstimate.max
                        ? cpiEstimate.min
                        : `${cpiEstimate.min} - ${cpiEstimate.max}`}{" "}
                      Coins
                    </CpiBoxCpiText>
                  </CpiBoxCpi>
                  <CpiBoxNote>* CPI can change over time</CpiBoxNote>
                  {!cpiFormOpened && cpi === "" && (
                    <CpiBoxButton>
                      <ButtonPrimarySmall onClick={openCpiForm}>
                        Set Maximum CPI
                      </ButtonPrimarySmall>
                    </CpiBoxButton>
                  )}
                  {cpiFormOpened && (
                    <CpiBoxForm>
                      <CpiBoxFormInput>
                        <InputText
                          type="text"
                          name="cpi"
                          value={cpi}
                          onChange={onCpiInputChange}
                          maxLength="4"
                        />
                      </CpiBoxFormInput>
                      <CpiBoxFormButton>
                        <ButtonPrimarySmall onClick={closeCpiForm}>
                          OK
                        </ButtonPrimarySmall>
                      </CpiBoxFormButton>
                    </CpiBoxForm>
                  )}
                  {!cpiFormOpened && cpi !== "" && (
                    <CpiBoxCpiSet>
                      <CpiBoxCpiSetText>Max. CPI: {cpi}</CpiBoxCpiSetText>
                      <ButtonPrimarySmall onClick={openCpiForm}>
                        Change
                      </ButtonPrimarySmall>
                    </CpiBoxCpiSet>
                  )}
                </CpiBox>
                <InputWithLabel
                  label="Post Name (optional)"
                  width="100%"
                  marginTop={css.VERTICAL_BOX_MARGIN_MEDIUM}
                  tip="The post name will not be visible to anyone else."
                >
                  <InputText
                    type="text"
                    name="name"
                    value={name}
                    placeholder="Enter the post name for your reference."
                    onChange={onNameInputChange}
                    maxLength={30}
                  />
                </InputWithLabel>
                <PostButton>
                  <ButtonPrimary
                    onClick={onPostSubmit}
                    disabled={submitLoading}
                    minWidth="111px"
                  >
                    {submitLoading ? <SmallSpinner /> : buttonText}
                  </ButtonPrimary>
                </PostButton>
                {warnings.length !== 0 && (
                  <Warning>
                    There are errors in the form. Please check the entered
                    information.
                  </Warning>
                )}
                {submitError && <Warning>{submitError}</Warning>}
              </Content>
            </>
          )}
        </FormBox>
      </CreatePost>
      {targetingOpened && (
        <>
          <Backdrop
            onClick={closeTargeting}
            parameters="login"
            background="rgba(0, 0, 0, 0.7)"
          />
          <TargetingWrap>
            <ModalWindow
              handleModalClose={closeTargeting}
              fullScreenHeight={true}
              title="Country Targeting"
            >
              <TargetingForm
                allCountries={allCountries}
                selectedCountries={countries}
                updateCountries={updateCountries}
                closeTargeting={closeTargeting}
                cpi={cpiEstimate}
              />
            </ModalWindow>
          </TargetingWrap>
        </>
      )}
      {confirmTemplate && (
        <>
          <Backdrop
            onClick={closeConfirmModal}
            background="rgba(0, 0, 0, 0.7)"
          />
          <PromptModal>
            <ModalWindow
              title="Add Post Template"
              handleModalClose={closeConfirmModal}
              fullScreenHeight={true}
            >
              <ConfirmTemplateWrap>
                <ConfirmTemplateText>
                  Inserting the template text will remove the content you have
                  created so far. Do you want to proceed?
                </ConfirmTemplateText>
                <ConfirmTemplateButtons>
                  <ButtonWhite onClick={closeConfirmModal}>Cancel</ButtonWhite>
                  <ButtonPrimary onClick={addTemplateText}>
                    Continue
                  </ButtonPrimary>
                </ConfirmTemplateButtons>
              </ConfirmTemplateWrap>
            </ModalWindow>
          </PromptModal>
        </>
      )}
      {blockModal && (
        <>
          <Backdrop onClick={closeBlockModal} background="rgba(0, 0, 0, 0.7)" />
          <PromptModal>
            <ModalWindow
              title="Leave and Discard Changes"
              handleModalClose={closeBlockModal}
              fullScreenHeight={true}
            >
              <ConfirmTemplateWrap>
                <ConfirmTemplateText>
                  You are about to leave the post editing form. Any changes you
                  have made will be lost.
                </ConfirmTemplateText>
                <ConfirmTemplateButtons>
                  <ButtonWhite onClick={closeBlockModal}>Cancel</ButtonWhite>
                  <ButtonPrimary onClick={confirmLeaveForm}>
                    Leave
                  </ButtonPrimary>
                </ConfirmTemplateButtons>
              </ConfirmTemplateWrap>
            </ModalWindow>
          </PromptModal>
        </>
      )}
    </Layout>
  );
};
