import styled from "styled-components";
import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import PayoutSettings from "./PayoutSettings";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import Backdrop from "components/other/Backdrop";
import MessageWindow from "components/other/MessageWindow";

const ModalWrap = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export default () => {
  const [errorWindow, setErrorWindow] = useState(false);
  const [changePaymentMethodForm, setChangePaymentMethodForm] = useState(false);
  const [methodSelectionOpened, setMethodSelectionOpened] = useState(false);
  const [methodSelected, setMethodSelected] = useState(null);
  const [loadingChangeMethod, setLoadingChangeMethod] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [methodDetailsForm, setMethodDetailsForm] = useState(false);
  const [methodDetails, setMethodDetails] = useState({
    bankAccountNumber: "",
    bankRoutingNumber: "",
    bankIban: "",
    bankSwift: "",
    bankAccountName: "",
    bankAccountStreet: "",
    bankAccountCity: "",
    bankAccountZip: "",
    bankAccountCountry: "",
    skrillEmail: "",
    bitcoinAddress: "",
  });
  const [loadingUpdateMethodDetails, setLoadingUpdateMethodDetails] =
    useState(false);

  // Fetch data
  const PAYOUT_SETTINGS_DATA = gql`
    query GetPayoutSettingsData {
      payoutSettingsData {
        id
        payment_method
        payment_method_details {
          id
          bank_account_number
          bank_routing_number
          bank_iban
          bank_swift
          bank_account_name
          bank_street
          bank_city
          bank_zip
          bank_country
          skrill_email
          bitcoin_address
        }
      }
    }
  `;

  const { loading, data, error } = useQuery(PAYOUT_SETTINGS_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching settings data error", error);
    //captureException(error);
  }

  // handle Payment method selection
  const changePaymentMethodFormToggle = () => {
    if (methodSelectionOpened) {
      setMethodSelectionOpened(false);
      return;
    }
    setChangePaymentMethodForm(!changePaymentMethodForm);

    if (methodDetailsForm) setMethodDetailsForm(false);
  };

  const toggleMethodSelection = () => {
    setMethodSelectionOpened((prevState) => !prevState);
  };

  useEffect(() => {
    if (data?.payoutSettingsData) {
      setMethodSelected(data.payoutSettingsData.payment_method);
    }
  }, [data]);

  const onMethodSelect = (method) => {
    setMethodSelected(method);
    setMethodSelectionOpened(false);
    setWarnings([]);
  };

  const CHANGE_PAYMENT_METHOD = gql`
    mutation ChangePaymentMethod($paymentMethod: String!) {
      changePaymentMethod(paymentMethod: $paymentMethod) {
        id
        payment_method
      }
    }
  `;

  const [changePaymentMethod] = useMutation(CHANGE_PAYMENT_METHOD);

  const changeMethod = async () => {
    try {
      if (loadingChangeMethod) return;

      if (!methodSelected) {
        setWarnings(["paymentMethod"]);
        return;
      }

      setLoadingChangeMethod(true);
      setWarnings([]);

      const result = await changePaymentMethod({
        variables: {
          paymentMethod: methodSelected,
        },
      });

      setLoadingChangeMethod(false);
      setTimeout(changePaymentMethodFormToggle, 300);
    } catch (error) {
      //captureException(error);
      setLoadingChangeMethod(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // handle Method details form
  const methodDetailsFormToggle = () => {
    setMethodDetailsForm(!methodDetailsForm);

    if (changePaymentMethodForm) setChangePaymentMethodForm(false);
  };

  useEffect(() => {
    if (data?.payoutSettingsData) {
      if (data.payoutSettingsData.payment_method_details) {
        const {
          bank_account_number,
          bank_routing_number,
          bank_iban,
          bank_swift,
          bank_account_name,
          bank_street,
          bank_city,
          bank_zip,
          bank_country,
          skrill_email,
          bitcoin_address,
        } = data.payoutSettingsData.payment_method_details;

        setMethodDetails({
          bankAccountNumber: bank_account_number,
          bankRoutingNumber: bank_routing_number,
          bankIban: bank_iban,
          bankSwift: bank_swift,
          bankAccountName: bank_account_name,
          bankAccountStreet: bank_street,
          bankAccountCity: bank_city,
          bankAccountZip: bank_zip,
          bankAccountCountry: bank_country,
          skrillEmail: skrill_email,
          bitcoinAddress: bitcoin_address,
        });
      }
    }
  }, [data]);

  const UPDATE_PAYMENT_METHOD_DETAILS = gql`
    mutation UpdatePaymentMethodDetails(
      $bankAccountNumber: String!
      $bankRoutingNumber: String!
      $bankIban: String!
      $bankSwift: String!
      $bankAccountName: String!
      $bankAccountStreet: String!
      $bankAccountCity: String!
      $bankAccountZip: String!
      $bankAccountCountry: String!
      $skrillEmail: String!
      $bitcoinAddress: String!
    ) {
      updatePaymentMethodDetails(
        bankAccountNumber: $bankAccountNumber
        bankRoutingNumber: $bankRoutingNumber
        bankIban: $bankIban
        bankSwift: $bankSwift
        bankAccountName: $bankAccountName
        bankAccountStreet: $bankAccountStreet
        bankAccountCity: $bankAccountCity
        bankAccountZip: $bankAccountZip
        bankAccountCountry: $bankAccountCountry
        skrillEmail: $skrillEmail
        bitcoinAddress: $bitcoinAddress
      ) {
        id
        payment_method_details {
          id
          bank_account_number
          bank_routing_number
          bank_iban
          bank_swift
          bank_account_name
          bank_street
          bank_city
          bank_zip
          bank_country
          skrill_email
          bitcoin_address
        }
      }
    }
  `;

  const [updatePaymentMethodDetails] = useMutation(
    UPDATE_PAYMENT_METHOD_DETAILS
  );

  const handleDetailsInputChange = (e) => {
    setMethodDetails({ ...methodDetails, [e.target.name]: e.target.value });
  };

  const updateMethodDetails = async () => {
    try {
      if (loadingUpdateMethodDetails) return;

      setLoadingUpdateMethodDetails(true);
      setWarnings([]);

      const {
        bankAccountNumber,
        bankRoutingNumber,
        bankIban,
        bankSwift,
        bankAccountName,
        bankAccountStreet,
        bankAccountCity,
        bankAccountZip,
        bankAccountCountry,
        skrillEmail,
        bitcoinAddress,
      } = methodDetails;

      const result = await updatePaymentMethodDetails({
        variables: {
          bankAccountNumber,
          bankRoutingNumber,
          bankIban,
          bankSwift,
          bankAccountName,
          bankAccountStreet,
          bankAccountCity,
          bankAccountZip,
          bankAccountCountry,
          skrillEmail,
          bitcoinAddress,
        },
      });

      console.log("result", result);
      setLoadingUpdateMethodDetails(false);
      setTimeout(methodDetailsFormToggle, 300);
    } catch (error) {
      console.log("updateMethodDetails error", error);
      //captureException(error);
      setLoadingChangeMethod(false);
      setLoadingUpdateMethodDetails();
      openErrorWindow();
      return;
    }
  };

  // Error handling
  const openErrorWindow = () => {
    setErrorWindow(true);
  };

  const handleModalClose = (modal) => {
    if (modal === "error") {
      setErrorWindow(false);
    }
  };

  // console.log({ data });
  // console.log({ methodSelected });
  // console.log({ methodDetails });

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner />
        </LoadingBox>
      )}
      {data && (
        <PayoutSettings
          data={data}
          changePaymentMethodForm={changePaymentMethodForm}
          changePaymentMethodFormToggle={changePaymentMethodFormToggle}
          methodSelectionOpened={methodSelectionOpened}
          toggleMethodSelection={toggleMethodSelection}
          onMethodSelect={onMethodSelect}
          methodSelected={methodSelected}
          changeMethod={changeMethod}
          warnings={warnings}
          loadingChangeMethod={loadingChangeMethod}
          methodDetailsForm={methodDetailsForm}
          methodDetailsFormToggle={methodDetailsFormToggle}
          methodDetails={methodDetails}
          updateMethodDetails={updateMethodDetails}
          loadingUpdateMethodDetails={loadingUpdateMethodDetails}
          handleDetailsInputChange={handleDetailsInputChange}
        />
      )}
      {errorWindow && (
        <ModalWrap>
          <Backdrop
            onClick={handleModalClose}
            parameters="error"
            background="rgba(0, 0, 0, 0.7)"
          />
          <MessageWindow handleModalClose={handleModalClose} type="error" />
        </ModalWrap>
      )}
    </>
  );
};
