import { gql, useQuery } from "@apollo/client";

import { AUTH_ID } from "constants";
import SideContest from "./SideContest";

export default () => {
  // Fetch data
  const GET_CONTEST_DATA = gql`
    query GetContestData {
      contestData {
        ongoingPrizes
        ongoingEnds
        ongoingPoints
        rankingGroup
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_CONTEST_DATA, {
    // fetchPolicy: "network-only",
    pollInterval: 300000,
  });

  if (error) {
    console.log("fetching contest data error", error);
    //captureException(error);
  }

  return <SideContest data={data} />;
};
