import { isStrongPassword } from "validator";
import { useDispatch } from "react-redux";
import validator from "validator";

const { blacklist } = validator;

export const getCountriesByPLevels = (countriesData) => {
  if (countriesData.length === 0) {
    return {
      l1: [],
      l2: [],
      l3: [],
    };
  }

  if (countriesData.length === 1) {
    return {
      l1: [],
      l2: [],
      l3: countriesData,
    };
  }

  countriesData.sort((a, b) => a.cpi - b.cpi);

  if (countriesData.length === 2) {
    return {
      l1: [],
      l2: [countriesData[1]],
      l3: [countriesData[0]],
    };
  }

  if (countriesData.length === 3) {
    return {
      l1: [countriesData[2]],
      l2: [countriesData[1]],
      l3: [countriesData[0]],
    };
  }

  const minCpi = countriesData[0].cpi;
  const maxCpi = countriesData[countriesData.length - 1].cpi;

  const levelCpiRange = (maxCpi - minCpi) / 3;

  const l2limit = minCpi + levelCpiRange;
  const l1limit = minCpi + 2 * levelCpiRange;

  const l3countries = countriesData.filter((country) => country.cpi <= l2limit);
  const l2countries = countriesData.filter(
    (country) => country.cpi > l2limit && country.cpi <= l1limit
  );
  const l1countries = countriesData.filter((country) => country.cpi > l1limit);

  return {
    l1: l1countries,
    l2: l2countries,
    l3: l3countries,
  };
};

export const getCpiEstimate = (countriesByPLevels, pLevel) => {
  const countries = countriesByPLevels[pLevel];

  let countriesToInclude = countries;

  if (countriesToInclude.length < 20) {
    // too few countries in included in estimate, add higher level
    if (pLevel === "l3") {
      countriesToInclude = [...countriesToInclude, ...countriesByPLevels.l2];

      if (countriesToInclude.length < 20) {
        // still too few countries in included in estimate, add even higher level
        countriesToInclude = [...countriesToInclude, ...countriesByPLevels.l1];
      }
    }

    if (pLevel === "l2") {
      countriesToInclude = [...countriesToInclude, ...countriesByPLevels.l1];
    }
  }

  countriesToInclude.sort((a, b) => a.cpi - b.cpi);

  if (countriesToInclude.length === 0) return { min: 0, max: 0 };

  const minCpi = countriesToInclude[0].cpi;
  const maxCpi = countriesToInclude[countriesToInclude.length - 1].cpi;

  return { min: minCpi, max: maxCpi };
};

export const dateFormatted = (date, includeYear = true) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let dateFormatted = monthNames[date.getMonth()] + " " + date.getDate();

  if (includeYear) {
    dateFormatted = dateFormatted + ", " + date.getFullYear();
  }

  return dateFormatted;
};

export const dateFormattedUTC = (date, includeYear = true) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let dateFormatted = monthNames[date.getUTCMonth()] + " " + date.getUTCDate();

  if (includeYear) {
    dateFormatted = dateFormatted + ", " + date.getUTCFullYear();
  }

  return dateFormatted;
};

export const addToStateArray = (array, item, setArray) => {
  console.log({ array, item, setArray });
  const newArray = [...array];

  newArray.push(item);
  console.log({ newArray });

  setArray(newArray);
};

export const removeFromStateArray = (array, item, setArray) => {
  const newArray = [...array];

  const index = newArray.indexOf(item);
  if (index > -1) {
    newArray.splice(index, 1);
  }

  setArray(newArray);
};

export const validatePasswordString = (password) => {
  return isStrongPassword(password, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
  });
};

export const sanitizedUsername = (username) => {
  let cleaning = username.replace(/\\/g, "");
  cleaning = cleaning.replace("]", "");

  return blacklist(cleaning, '!?/"()[:&#@;$+_=*%^');
};

export const fetchWithTimeout = (ms, fetchPromise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error("Fetch timeout"));
    }, ms);
    fetchPromise.then(resolve, reject);
  });
};

export const getIp = async () => {
  const result = await fetch("https://api.ipify.org?format=json").then(
    (response) => {
      return response.json();
    }
  );
  return result;
};

export const timeSince = (date) => {
  const now = new Date();
  const secondsPast = Math.floor((now - date) / 1000);

  if (secondsPast < 60) {
    return `${secondsPast}s`;
  } else if (secondsPast < 3600) {
    const minutes = Math.floor(secondsPast / 60);
    return `${minutes}m`;
  } else if (secondsPast < 86400) {
    const hours = Math.floor(secondsPast / 3600);
    return `${hours}h`;
  } else {
    const days = Math.floor(secondsPast / 86400);
    return `${days}d`;
  }
};

export const sumImpsCpi = (impressions) => {
  let sum = 0;
  for (const impression of impressions) {
    sum += impression.cpi;
  }
  return sum;
};

export const sortArrayByProperty = (array, property, desc = false) => {
  return array.slice().sort((a, b) => {
    if (a[property] < b[property]) {
      return desc ? 1 : -1;
    }
    if (a[property] > b[property]) {
      return desc ? -1 : 1;
    }
    return 0;
  });
};

export const getTimeAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const getRandomNumberInRange = (min, max) => {
  if (min > max) {
    [min, max] = [max, min];
  }
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const daysUntil = (targetDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const now = new Date();
  const endOfTargetDate = new Date(targetDate);

  return Math.ceil(Math.abs((endOfTargetDate - now) / oneDay));
};

export const getRandomArrayItem = (array) => {
  const position = getRandomNumberInRange(0, array.length - 1);
  console.log({ position });

  return array[position];
};

export const preloadImage = (image) => {
  const preloadedImage = new Image();
  preloadedImage.src = image;
};
