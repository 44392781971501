// @flow
import {
  SAVE_PROFILE_DATA,
  SAVE_IP_ADDRESS,
  UPDATE_PROGRESS_POSTS,
  UPDATE_PROGRESS_VIEWS,
} from "./actionTypes";

const INIT_STATE = {
  profileData: null,
  ipAddress: null,
  hasPost: false,
  hasViews: false,
};
const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
      };

    case SAVE_IP_ADDRESS:
      return {
        ...state,
        ipAddress: action.payload,
      };

    case UPDATE_PROGRESS_POSTS:
      return {
        ...state,
        hasPost: action.payload,
      };

    case UPDATE_PROGRESS_VIEWS:
      return {
        ...state,
        hasViews: action.payload,
      };

    default:
      return state;
  }
};

export default User;
